export const STRIPE_CONSTANTS = {
  CURRENCY: 'eur',
  LOCALE: 'es',
  PAYMENT_METHODS: ['card'],
  CARD_ELEMENT_OPTIONS: {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
    hidePostalCode: true
  }
};

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  REFUNDED: 'refunded'
};

export const PAYMENT_ERRORS = {
  CARD_ERROR: {
    code: 'card_error',
    message: 'Error en la tarjeta. Por favor, verifica los datos.'
  },
  INSUFFICIENT_FUNDS: {
    code: 'insufficient_funds',
    message: 'Fondos insuficientes en la tarjeta.'
  },
  CARD_DECLINED: {
    code: 'card_declined',
    message: 'La tarjeta ha sido rechazada.'
  },
  EXPIRED_CARD: {
    code: 'expired_card',
    message: 'La tarjeta ha expirado.'
  },
  PROCESSING_ERROR: {
    code: 'processing_error',
    message: 'Error al procesar el pago. Por favor, intenta de nuevo.'
  },
  STOCK_ERROR: {
    code: 'stock_error',
    message: 'Algunos productos no están disponibles en la cantidad solicitada.'
  },
  VALIDATION_ERROR: {
    code: 'validation_error',
    message: 'Por favor, completa todos los campos correctamente.'
  },
  SERVER_ERROR: {
    code: 'server_error',
    message: 'Error en el servidor. Por favor, intenta de nuevo.'
  }
};

export const PAYMENT_MESSAGES = {
  PROCESSING: 'Procesando tu pago...',
  SUCCESS: '¡Pago completado con éxito!',
  CREATING_ORDER: 'Creando tu pedido...',
  VERIFYING_STOCK: 'Verificando disponibilidad...',
  UPDATING_INVENTORY: 'Actualizando inventario...',
  ORDER_COMPLETED: '¡Pedido completado con éxito!'
}; 