import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';

const StyledMalalaLogo = styled.svg`
  width: 45px;
  height: 45px;
  margin-right: 1rem;
`;

const MalalaLogo = () => {
  return (
    <StyledMalalaLogo viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle 
        cx="512" 
        cy="512" 
        r="500" 
        stroke="black"
        strokeWidth="16"
      />
      <text 
        x="512" 
        y="512" 
        textAnchor="middle" 
        dominantBaseline="middle" 
        fill="black"
        style={{ fontSize: '300px', fontFamily: 'Trajan Pro, serif', fontWeight: '300' }}
      >
        ML
      </text>
    </StyledMalalaLogo>
  );
};

const LoginPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-top: 120px;
  background-color: white;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 2rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  color: black;
  letter-spacing: 2px;
  font-weight: 500;
`;

const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 3rem;
  font-weight: 400;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.9rem;
  background: transparent;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-bottom-color: black;
  }

  &::placeholder {
    color: #999;
  }
`;

const Button = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: black;
  color: white;
  border: none;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Error = styled(motion.div)`
  padding: 0.75rem;
  background: #ff4444;
  color: white;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const ForgotPassword = styled(Link)`
  color: #666;
  text-decoration: none;
  font-size: 0.8rem;
  text-align: left;
  margin-top: -1rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const RegisterLink = styled(Link)`
  display: block;
  color: #666;
  text-decoration: none;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 2rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const { login, error, clearError } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) clearError();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const user = await login(formData.email, formData.password);
      // Solo redirigimos al dashboard si es el admin
      if (formData.email === 'admin@malala.com') {
        navigate('/dashboard');
      } else {
        navigate('/'); // Usuarios normales van a la página principal
      }
    } catch (error) {
      let errorMessage = 'Error al iniciar sesión';
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = 'Usuario no encontrado';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Contraseña incorrecta';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Correo electrónico inválido';
          break;
        default:
          errorMessage = error.message;
      }
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginPage>
      <LogoContainer to="/">
        <MalalaLogo />
        <LogoText>MALALA</LogoText>
      </LogoContainer>

      <LoginContainer>
        <Title>Inicia sesión</Title>

        <Form onSubmit={handleSubmit}>
          {error && (
            <Error
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              {error}
            </Error>
          )}

          <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="EMAIL"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Input
              type="password"
              name="password"
              placeholder="CONTRASEÑA"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <ForgotPassword to="/forgot-password">
            ¿Has olvidado la contraseña?
          </ForgotPassword>

          <Button
            type="submit"
            disabled={loading}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
          >
            {loading ? 'Iniciando sesión...' : 'Iniciar sesión'}
          </Button>
        </Form>

        <RegisterLink to="/register">
          ¿No tienes una cuenta? Regístrate
        </RegisterLink>
      </LoginContainer>
    </LoginPage>
  );
};

export default Login; 