import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCXTp9lIP7Z-ycE35U3are3J6xsWgWw1hs",
  authDomain: "malala-421e9.firebaseapp.com",
  projectId: "malala-421e9",
  storageBucket: "malala-421e9.firebasestorage.app",
  messagingSenderId: "114162077433",
  appId: "1:114162077433:web:02d0b664cd6ac480525c28",
  measurementId: "G-VE0E6XFGBF"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export default app; 
