import { PAYMENT_ERRORS } from '../constants/stripe';

export const formatStripeError = (error) => {
  switch (error.code) {
    case 'card_error':
      return PAYMENT_ERRORS.CARD_ERROR.message;
    case 'insufficient_funds':
      return PAYMENT_ERRORS.INSUFFICIENT_FUNDS.message;
    case 'card_declined':
      return PAYMENT_ERRORS.CARD_DECLINED.message;
    case 'expired_card':
      return PAYMENT_ERRORS.EXPIRED_CARD.message;
    case 'processing_error':
      return PAYMENT_ERRORS.PROCESSING_ERROR.message;
    default:
      return error.message || PAYMENT_ERRORS.SERVER_ERROR.message;
  }
};

export const validatePaymentForm = (formData) => {
  const errors = {};
  
  if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
    errors.email = 'Email inválido';
  }
  
  if (!formData.firstName?.trim()) {
    errors.firstName = 'El nombre es requerido';
  }
  
  if (!formData.lastName?.trim()) {
    errors.lastName = 'Los apellidos son requeridos';
  }
  
  if (!formData.address?.trim()) {
    errors.address = 'La dirección es requerida';
  }
  
  if (!formData.city?.trim()) {
    errors.city = 'La ciudad es requerida';
  }
  
  if (!formData.postalCode || !/^\d{5}$/.test(formData.postalCode)) {
    errors.postalCode = 'Código postal inválido';
  }
  
  if (!formData.phone || !/^\+?[\d\s-]{9,}$/.test(formData.phone)) {
    errors.phone = 'Teléfono inválido';
  }
  
  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};

export const createPaymentIntent = async (amount, currency, metadata) => {
  try {
    const response = await fetch('/api/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        currency,
        metadata
      }),
    });

    if (!response.ok) {
      throw new Error('Error al crear el PaymentIntent');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating PaymentIntent:', error);
    throw error;
  }
};

export const formatAmount = (amount, currency = 'EUR') => {
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency
  }).format(amount);
};

export const getCardBrand = (brand) => {
  const brands = {
    visa: 'Visa',
    mastercard: 'Mastercard',
    amex: 'American Express',
    discover: 'Discover',
    jcb: 'JCB',
    diners: 'Diners Club',
    unionpay: 'UnionPay'
  };
  
  return brands[brand] || brand;
}; 