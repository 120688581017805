import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useCart } from '../../contexts/CartContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { SHIPPING_COST } from '../../config/constants';

const CartPageContainer = styled.div`
  max-width: ${props => props.theme.breakpoints.xl};
  margin: 0 auto;
  padding: ${props => props.theme.spacing.xxl} ${props => props.theme.spacing.xl};
  min-height: 100vh;
`;

const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.xl};
`;

const CartTitle = styled.h1`
  font-size: ${props => props.theme.typography.fontSize.xxl};
  font-weight: ${props => props.theme.typography.fontWeight.bold};
`;

const CartContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${props => props.theme.spacing.xl};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const CartItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.lg};
`;

const CartItem = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr auto;
  gap: 2rem;
  padding: 1.5rem;
  background: linear-gradient(145deg, #ffffff, #fafafa);
  border-radius: 16px;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 
    0 4px 12px rgba(0,0,0,0.03),
    0 8px 24px rgba(0,0,0,0.03),
    inset 0 0 0 1px rgba(0,0,0,0.08);

  &:hover {
    transform: translateY(-3px);
    background: linear-gradient(145deg, #ffffff, #ffffff);
    box-shadow: 
      0 8px 24px rgba(0,0,0,0.06),
      0 12px 32px rgba(0,0,0,0.06),
      inset 0 0 0 1.5px rgba(0,0,0,0.12);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 120px 1fr;
    padding: 1rem;
    gap: 1rem;
  }
`;

const ItemImage = styled.img`
  width: 200px;
  height: 250px;
  object-fit: cover;
  border-radius: 12px;
  background: white;
  transition: all 0.3s ease;
  box-shadow: 
    0 6px 16px rgba(0,0,0,0.08),
    0 3px 8px rgba(0,0,0,0.12),
    inset 0 0 0 1px rgba(0,0,0,0.04);

  &:hover {
    transform: scale(1.03) translateY(-2px);
    box-shadow: 
      0 12px 24px rgba(0,0,0,0.12),
      0 6px 12px rgba(0,0,0,0.08),
      inset 0 0 0 1px rgba(0,0,0,0.08);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 120px;
    height: 150px;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ItemName = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: ${props => props.theme.colors.text.primary};
  margin: 0;
`;

const ItemDetails = styled.p`
  font-size: 0.85rem;
  color: ${props => props.theme.colors.text.secondary};
  margin: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: inline-block;
`;

const ItemPrice = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${props => props.theme.colors.primary.main};
  margin: 0;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: linear-gradient(to right, #f8f9fa, #f3f4f6);
  padding: 0.6rem;
  border-radius: 8px;
  box-shadow: 
    0 1px 2px rgba(0,0,0,0.04),
    inset 0 0 0 1px rgba(0,0,0,0.05);
`;

const QuantityButton = styled.button`
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: ${props => props.theme.colors.text.primary};
  font-weight: 600;
  border: none;
  box-shadow: 
    0 2px 4px rgba(0,0,0,0.04),
    inset 0 0 0 1px rgba(0,0,0,0.08);

  &:hover {
    background: ${props => props.theme.colors.primary.main};
    color: white;
    box-shadow: 
      0 4px 8px rgba(0,0,0,0.08),
      inset 0 0 0 1px ${props => props.theme.colors.primary.main};
  }

  &:active {
    transform: scale(0.95);
  }
`;

const RemoveButton = styled.button`
  background: linear-gradient(to right, #fff5f5, #fff0f0);
  border: none;
  color: ${props => props.theme.colors.error};
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 
    0 1px 2px rgba(0,0,0,0.04),
    inset 0 0 0 1px ${props => props.theme.colors.error}20;

  &:hover {
    background: ${props => props.theme.colors.error}15;
    box-shadow: 
      0 2px 4px rgba(0,0,0,0.06),
      inset 0 0 0 1px ${props => props.theme.colors.error};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const CartSummary = styled.div`
  background: white;
  padding: ${props => props.theme.spacing.xl};
  border-radius: 12px;
  background: linear-gradient(145deg, #ffffff, #fafafa);
  box-shadow: 
    0 4px 8px rgba(0,0,0,0.04),
    0 1px 3px rgba(0,0,0,0.08),
    inset 0 0 0 1px rgba(0,0,0,0.05);
  height: fit-content;
  position: sticky;
  top: 100px;
`;

const SummaryTitle = styled.h2`
  font-size: ${props => props.theme.typography.fontSize.xl};
  margin-bottom: ${props => props.theme.spacing.lg};
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.sm};
  font-size: ${props => props.theme.typography.fontSize.md};
  color: ${props => props.total ? props.theme.colors.text.primary : props.theme.colors.text.secondary};
  font-weight: ${props => props.total ? props.theme.typography.fontWeight.bold : props.theme.typography.fontWeight.regular};
`;

const CheckoutButton = styled(motion.button)`
  width: 100%;
  padding: 1.2rem;
  background: #000000;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.1),
    0 8px 24px rgba(0, 0, 0, 0.08);

  &:hover {
    transform: translateY(-2px);
    background: #1a1a1a;
    box-shadow: 
      0 6px 16px rgba(0, 0, 0, 0.15),
      0 10px 32px rgba(0, 0, 0, 0.12);
  }

  &:active {
    transform: translateY(0) scale(0.98);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    background: #e5e7eb;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;

    &:hover {
      background: #e5e7eb;
      transform: none;
    }
  }
`;

const EmptyCartMessage = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing.xxl};
`;

const ContinueShoppingButton = styled(motion.button)`
  padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xl};
  background: ${props => props.theme.colors.primary.main};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: ${props => props.theme.spacing.lg};
`;

const CartPage = () => {
  const navigate = useNavigate();
  const { cart, updateQuantity, removeFromCart, calculateSubtotal, calculateItemPrice } = useCart();

  const subtotal = calculateSubtotal();
  const shipping = subtotal > 100 ? 0 : SHIPPING_COST;
  const total = subtotal + shipping;

  if (cart.length === 0) {
    return (
      <CartPageContainer>
        <EmptyCartMessage>
          <h2>Tu carrito está vacío</h2>
          <p>¿No sabes qué comprar? ¡Miles de productos te esperan!</p>
          <ContinueShoppingButton
            onClick={() => navigate('/shop')}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Continuar comprando
          </ContinueShoppingButton>
        </EmptyCartMessage>
      </CartPageContainer>
    );
  }

  return (
    <CartPageContainer>
      <CartHeader>
        <CartTitle>Tu Carrito</CartTitle>
      </CartHeader>

      <CartContent>
        <CartItems>
          {cart.map((item) => (
            <CartItem key={`${item.id}-${item.size}-${item.color}`}>
              <ItemImage 
                src={item.images?.[0] || '/placeholder-image.jpg'} 
                alt={item.name} 
              />
              <ItemInfo>
                <ItemName>{item.name}</ItemName>
                {item.size && <ItemDetails>Talla: {item.size}</ItemDetails>}
                {item.color && <ItemDetails>Color: {item.color}</ItemDetails>}
                <ItemPrice>
                  {item.discount > 0 ? (
                    <>
                      <span style={{ textDecoration: 'line-through', color: '#999', marginRight: '10px' }}>
                        €{item.price.toFixed(2)}
                      </span>
                      €{calculateItemPrice(item).toFixed(2)}
                      <span style={{ color: '#e53e3e', marginLeft: '10px' }}>
                        (-{item.discount}%)
                      </span>
                    </>
                  ) : (
                    `€${item.price.toFixed(2)}`
                  )}
                </ItemPrice>
                <QuantityControls>
                  <QuantityButton 
                    onClick={() => updateQuantity(item, Math.max(1, item.quantity - 1))}
                  >
                    -
                  </QuantityButton>
                  <span>{item.quantity}</span>
                  <QuantityButton 
                    onClick={() => updateQuantity(item, item.quantity + 1)}
                  >
                    +
                  </QuantityButton>
                </QuantityControls>
                <RemoveButton onClick={() => removeFromCart(item)}>
                  Eliminar
                </RemoveButton>
              </ItemInfo>
              <ItemPrice>
                €{(calculateItemPrice(item) * item.quantity).toFixed(2)}
              </ItemPrice>
            </CartItem>
          ))}
        </CartItems>

        <CartSummary>
          <SummaryTitle>Resumen del pedido</SummaryTitle>
          <SummaryRow>
            <span>Subtotal</span>
            <span>€{subtotal.toFixed(2)}</span>
          </SummaryRow>
          <SummaryRow>
            <span>Envío</span>
            <span>{shipping === 0 ? 'Gratis' : `€${shipping.toFixed(2)}`}</span>
          </SummaryRow>
          <SummaryRow total>
            <span>Total</span>
            <span>€{total.toFixed(2)}</span>
          </SummaryRow>
          <CheckoutButton
            onClick={() => navigate('/checkout')}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            disabled={cart.length === 0}
          >
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
            </svg>
            Proceder al pago
          </CheckoutButton>
        </CartSummary>
      </CartContent>
    </CartPageContainer>
  );
};

export default CartPage; 