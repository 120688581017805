import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    background: none;
  }

  body {
    font-family: ${props => props.theme.typography.fontFamily.primary};
    font-size: ${props => props.theme.typography.fontSize.md};
    line-height: ${props => props.theme.typography.lineHeight.normal};
    color: ${props => props.theme.colors.text.primary};
    background: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    position: relative;
    background: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.typography.fontFamily.primary};
    font-weight: ${props => props.theme.typography.fontWeight.regular};
    line-height: ${props => props.theme.typography.lineHeight.tight};
    margin: 0;
    letter-spacing: 0.5px;
  }

  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  ul, ol {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  input, textarea, select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: none;
  }

  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* Selection styles */
  ::selection {
    background: ${props => props.theme.colors.primary.main};
    color: ${props => props.theme.colors.secondary.main};
  }

  /* Transitions */
  * {
    transition: color ${props => props.theme.transitions.fast},
                background-color ${props => props.theme.transitions.fast},
                border-color ${props => props.theme.transitions.fast},
                box-shadow ${props => props.theme.transitions.fast};
  }

  /* Responsive font sizes */
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    html {
      font-size: 14px;
    }

    h1 {
      font-size: ${props => props.theme.typography.fontSize.xxl};
    }

    h2 {
      font-size: ${props => props.theme.typography.fontSize.xl};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    html {
      font-size: 13px;
    }

    h1 {
      font-size: ${props => props.theme.typography.fontSize.xl};
    }

    h2 {
      font-size: ${props => props.theme.typography.fontSize.lg};
    }

    button, input, select {
      min-height: 44px; /* Para mejor accesibilidad táctil */
    }
  }
`;

export default GlobalStyles; 