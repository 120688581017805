import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { collection, query, where, getDocs, getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';

const ProfileContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 120px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
`;

const Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #666;
  text-transform: uppercase;
`;

const UserInfo = styled.div`
  flex: 1;
`;

const UserName = styled.h1`
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const UserEmail = styled.p`
  color: #666;
  font-size: 1rem;
`;

const ProfileGrid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Sidebar = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  height: fit-content;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  color: ${props => props.active ? '#000' : '#666'};
  background: ${props => props.active ? '#f8f8f8' : 'transparent'};
  font-weight: ${props => props.active ? '500' : '400'};

  &:hover {
    background: #f8f8f8;
    color: #000;
  }
`;

const MainContent = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OrdersGrid = styled.div`
  display: grid;
  gap: 1.5rem;
`;

const OrderCard = styled(motion.div)`
  background: white;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  }
`;

const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
`;

const OrderId = styled.span`
  font-size: 0.875rem;
  color: #666;
  background: #f8f8f8;
  padding: 0.5rem 1rem;
  border-radius: 20px;
`;

const OrderDate = styled.span`
  font-size: 0.875rem;
  color: #666;
`;

const OrderItems = styled.div`
  margin: 1rem 0;
`;

const OrderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #f5f5f5;

  &:last-child {
    border-bottom: none;
  }
`;

const ItemImage = styled.div`
  width: 50px;
  height: 50px;
  background: #f8f8f8;
  border-radius: 8px;
`;

const ItemInfo = styled.div`
  flex: 1;
`;

const ItemName = styled.h4`
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

const ItemDetails = styled.p`
  font-size: 0.75rem;
  color: #666;
`;

const OrderTotal = styled.div`
  text-align: right;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const NoOrders = styled.div`
  text-align: center;
  padding: 3rem;
  color: #666;
  background: #f8f8f8;
  border-radius: 12px;
`;

const LogoutButton = styled(motion.button)`
  width: 100%;
  padding: 1rem;
  background: #000;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease;

  &:hover {
    background: #333;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background: #000;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease;

  &:hover {
    background: #333;
  }
`;

const HelpSection = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
`;

const HelpItem = styled.div`
  margin-bottom: 1rem;

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

const Profile = () => {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState('orders');
  const [userProfile, setUserProfile] = useState({
    name: '',
    email: currentUser?.email || ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        // Cargar datos del perfil
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProfile(prev => ({
            ...prev,
            name: userData.name || '',
          }));
        }

        // Cargar pedidos
        const ordersQuery = query(
          collection(db, 'orders'),
          where('userId', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(ordersQuery);
        const ordersData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setOrders(ordersData);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        toast.error('Error al cargar los datos del perfil');
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const getUserInitials = () => {
    if (userProfile.name) {
      return userProfile.name.charAt(0).toUpperCase();
    }
    return currentUser.email.charAt(0).toUpperCase();
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, {
        name: userProfile.name,
        email: userProfile.email,
        updatedAt: new Date()
      }, { merge: true });
      
      toast.success('Perfil actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  if (loading) {
    return <ProfileContainer>Cargando...</ProfileContainer>;
  }

  const renderOrders = () => (
    <>
      <SectionTitle>
        Historial de Pedidos
        <span style={{ fontSize: '0.875rem', color: '#666' }}>
          {orders.length} pedidos
        </span>
      </SectionTitle>

      {orders.length > 0 ? (
        <OrdersGrid>
          {orders.map(order => (
            <OrderCard
              key={order.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <OrderHeader>
                <OrderId>Pedido #{order.id.slice(-6)}</OrderId>
                <OrderDate>
                  {new Date(order.createdAt?.toDate()).toLocaleDateString()}
                </OrderDate>
              </OrderHeader>
              <OrderItems>
                {order.items.map((item, index) => (
                  <OrderItem key={index}>
                    <ItemImage />
                    <ItemInfo>
                      <ItemName>{item.name}</ItemName>
                      <ItemDetails>
                        Cantidad: {item.quantity}
                        {item.size && ` • Talla: ${item.size}`}
                        {item.color && ` • Color: ${item.color}`}
                      </ItemDetails>
                    </ItemInfo>
                  </OrderItem>
                ))}
              </OrderItems>
              <OrderTotal>
                Total: €{order.total.toFixed(2)}
              </OrderTotal>
            </OrderCard>
          ))}
        </OrdersGrid>
      ) : (
        <NoOrders>
          No tienes pedidos realizados todavía
        </NoOrders>
      )}
    </>
  );

  const renderReturns = () => (
    <>
      <SectionTitle>Devoluciones</SectionTitle>
      <NoOrders>
        No tienes devoluciones activas
      </NoOrders>
    </>
  );

  const renderPaymentMethods = () => (
    <>
      <SectionTitle>Métodos de Pago</SectionTitle>
      <NoOrders>
        No hay métodos de pago guardados
      </NoOrders>
    </>
  );

  const renderProfile = () => (
    <>
      <SectionTitle>Tu Perfil</SectionTitle>
      <form onSubmit={handleProfileUpdate}>
        <FormGroup>
          <label>Nombre</label>
          <input
            type="text"
            value={userProfile.name}
            onChange={(e) => setUserProfile(prev => ({ ...prev, name: e.target.value }))}
            placeholder="Tu nombre"
          />
        </FormGroup>
        <FormGroup>
          <label>Email</label>
          <input
            type="email"
            value={userProfile.email}
            disabled
          />
        </FormGroup>
        <Button type="submit">
          Guardar Cambios
        </Button>
      </form>
    </>
  );

  const renderHelp = () => (
    <>
      <SectionTitle>Ayuda</SectionTitle>
      <HelpSection>
        <HelpItem>
          <h3>Preguntas Frecuentes</h3>
          <p>Encuentra respuestas a las preguntas más comunes sobre pedidos, envíos y devoluciones.</p>
        </HelpItem>
        <HelpItem>
          <h3>Contacto</h3>
          <p>¿Necesitas ayuda adicional? Contáctanos en info@malala.shop</p>
        </HelpItem>
      </HelpSection>
    </>
  );

  return (
    <ProfileContainer>
      <ProfileHeader>
        <Avatar>{getUserInitials()}</Avatar>
        <UserInfo>
          <UserName>{userProfile.name || 'Mi Cuenta'}</UserName>
          <UserEmail>{userProfile.email}</UserEmail>
        </UserInfo>
      </ProfileHeader>

      <ProfileGrid>
        <Sidebar>
          <MenuList>
            <MenuItem 
              active={activeSection === 'orders'} 
              onClick={() => setActiveSection('orders')}
            >
              Tus Pedidos
            </MenuItem>
            <MenuItem 
              active={activeSection === 'returns'} 
              onClick={() => setActiveSection('returns')}
            >
              Devoluciones
            </MenuItem>
            <MenuItem 
              active={activeSection === 'payment'} 
              onClick={() => setActiveSection('payment')}
            >
              Métodos de Pago
            </MenuItem>
            <MenuItem 
              active={activeSection === 'profile'} 
              onClick={() => setActiveSection('profile')}
            >
              Perfil
            </MenuItem>
            <MenuItem 
              active={activeSection === 'help'} 
              onClick={() => setActiveSection('help')}
            >
              Ayuda
            </MenuItem>
          </MenuList>
          <LogoutButton
            onClick={handleLogout}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Cerrar Sesión
          </LogoutButton>
        </Sidebar>

        <MainContent>
          {activeSection === 'orders' && renderOrders()}
          {activeSection === 'returns' && renderReturns()}
          {activeSection === 'payment' && renderPaymentMethods()}
          {activeSection === 'profile' && renderProfile()}
          {activeSection === 'help' && renderHelp()}
        </MainContent>
      </ProfileGrid>
    </ProfileContainer>
  );
};

export default Profile; 