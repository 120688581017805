import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const FooterContainer = styled.footer`
  background: white;
  color: ${props => props.theme.colors.text.primary};
  padding: 4rem 2rem;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const FooterSection = styled.div`
  h3 {
    font-family: ${props => props.theme.typography.fontFamily.primary};
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: ${props => props.theme.colors.text.primary};
    letter-spacing: 0.5px;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  color: ${props => props.theme.colors.text.primary};
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
`;

const ExternalLink = styled.a`
  display: block;
  color: ${props => props.theme.colors.text.primary};
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  letter-spacing: 0.3px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>AYUDA</h3>
          <FooterLink to="/mi-cuenta">MI CUENTA MALALA</FooterLink>
          <FooterLink to="/articulos-tallas">ARTÍCULOS Y TALLAS</FooterLink>
          <FooterLink to="/opciones-regalo">OPCIONES PARA REGALO</FooterLink>
          <FooterLink to="/envios">ENVÍOS</FooterLink>
          <FooterLink to="/pagos">PAGOS Y FACTURAS</FooterLink>
          <FooterLink to="/mis-compras">MIS COMPRAS</FooterLink>
          <FooterLink to="/devoluciones">CAMBIOS, DEVOLUCIONES Y REEMBOLSOS</FooterLink>
          <FooterLink to="/pre-owned">MALALA PRE-OWNED</FooterLink>
          <FooterLink to="/experiencias">EXPERIENCIAS MALALA</FooterLink>
        </FooterSection>

        <FooterSection>
          <h3>SÍGUENOS</h3>
          <FooterLink to="/newsletter">NEWSLETTER</FooterLink>
          <ExternalLink href="https://tiktok.com" target="_blank" rel="noopener noreferrer">TIKTOK</ExternalLink>
          <ExternalLink href="https://instagram.com" target="_blank" rel="noopener noreferrer">INSTAGRAM</ExternalLink>
          <ExternalLink href="https://facebook.com" target="_blank" rel="noopener noreferrer">FACEBOOK</ExternalLink>
          <ExternalLink href="https://twitter.com" target="_blank" rel="noopener noreferrer">X</ExternalLink>
          <ExternalLink href="https://pinterest.com" target="_blank" rel="noopener noreferrer">PINTEREST</ExternalLink>
          <ExternalLink href="https://youtube.com" target="_blank" rel="noopener noreferrer">YOUTUBE</ExternalLink>
        </FooterSection>

        <FooterSection>
          <h3>EMPRESA</h3>
          <FooterLink to="/quienes-somos">QUIÉNES SOMOS</FooterLink>
          <FooterLink to="/join-life">JOIN LIFE</FooterLink>
          <FooterLink to="/oficinas">OFICINAS</FooterLink>
          <FooterLink to="/tiendas">TIENDAS</FooterLink>
          <FooterLink to="/trabaja-con-nosotros">TRABAJA CON NOSOTROS</FooterLink>
        </FooterSection>

        <FooterSection>
          <h3>POLÍTICAS</h3>
          <FooterLink to="/privacidad">POLÍTICA DE PRIVACIDAD</FooterLink>
          <FooterLink to="/condiciones-compra">CONDICIONES DE COMPRA</FooterLink>
          <FooterLink to="/tarjeta-regalo">CONDICIONES TARJETA REGALO</FooterLink>
          <FooterLink to="/video-regalo">CONDICIONES DE USO DE VÍDEO REGALO</FooterLink>
          <FooterLink to="/cookies">CONFIGURACIÓN DE COOKIES</FooterLink>
        </FooterSection>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer; 