import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useCart } from '../../contexts/CartContext';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from '../../config/stripe';
import { toast } from 'react-hot-toast';
import CheckoutForm from './CheckoutForm';
import { SHIPPING_COST } from '../../config/constants';

const CheckoutContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 120px;
`;

const LoadingOverlay = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

const LoadingSpinner = styled(motion.div)`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #000;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const LoadingText = styled.p`
  color: #666;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 1rem 2rem;
  background: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: #333;
  }
`;

const OrderSummary = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 8px;
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  
  &:last-child {
    border-top: 1px solid #ddd;
    margin-top: 1rem;
    padding-top: 1rem;
    font-weight: bold;
  }
`;

const CheckoutPage = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { cart, calculateSubtotal } = useCart();
  const navigate = useNavigate();

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    return subtotal + SHIPPING_COST;
  };

  useEffect(() => {
    if (!cart.length) {
      navigate('/cart');
      return;
    }

    const initializePayment = async () => {
      try {
        setLoading(true);
        const total = calculateTotal();
        const response = await fetch('https://us-central1-malala-421e9.cloudfunctions.net/createPaymentIntent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: Math.round(total * 100), // Convertir a céntimos
            currency: 'eur',
          }),
        });

        if (!response.ok) {
          throw new Error('Error al inicializar el pago');
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (err) {
        console.error('Error:', err);
        setError(err.message);
        toast.error('Error al inicializar el pago. Por favor, inténtalo de nuevo.');
      } finally {
        setLoading(false);
      }
    };

    initializePayment();
  }, [cart, navigate]);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#000000',
        colorBackground: '#ffffff',
        colorText: '#000000',
        colorDanger: '#ff4444',
        fontFamily: 'system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '4px',
      },
    },
  };

  if (loading) {
    return (
      <CheckoutContainer>
        <LoadingOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <LoadingSpinner
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          />
          <LoadingText>Cargando checkout...</LoadingText>
        </LoadingOverlay>
      </CheckoutContainer>
    );
  }

  if (error) {
    return (
      <CheckoutContainer>
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <h2>Error</h2>
          <p>{error}</p>
          <Button onClick={() => navigate('/cart')}>Volver al carrito</Button>
        </div>
      </CheckoutContainer>
    );
  }

  return (
    <CheckoutContainer>
      <OrderSummary>
        <SummaryItem>
          <span>Subtotal</span>
          <span>€{calculateSubtotal().toFixed(2)}</span>
        </SummaryItem>
        <SummaryItem>
          <span>Gastos de envío</span>
          <span>€{SHIPPING_COST.toFixed(2)}</span>
        </SummaryItem>
        <SummaryItem>
          <span>Total</span>
          <span>€{calculateTotal().toFixed(2)}</span>
        </SummaryItem>
      </OrderSummary>

      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </CheckoutContainer>
  );
};

export default CheckoutPage; 