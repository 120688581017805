import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';

const MenuButton = styled(motion.button)`
  position: fixed;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  cursor: pointer;
  z-index: 1000;
  display: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MenuIcon = styled(motion.div)`
  width: 20px;
  height: 2px;
  background: ${props => props.isOpen ? 'transparent' : '#000'};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background: #000;
    transition: transform 0.3s ease;
  }

  &::before {
    transform: translateY(${props => props.isOpen ? '0' : '-6px'}) 
               rotate(${props => props.isOpen ? '45deg' : '0'});
  }

  &::after {
    transform: translateY(${props => props.isOpen ? '0' : '6px'}) 
               rotate(${props => props.isOpen ? '-45deg' : '0'});
  }
`;

const MenuOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.98);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const MenuItem = styled(motion.div)`
  margin: 1rem 0;
  text-align: center;
`;

const MenuLink = styled(Link)`
  font-size: 1.5rem;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background: #000;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }
`;

const ProfileSection = styled(motion.div)`
  margin-top: 2rem;
  text-align: center;
`;

const ProfileButton = styled(motion.button)`
  background: none;
  border: 2px solid #000;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    background: #000;
    color: #fff;
  }
`;

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      setIsOpen(false);
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  const menuVariants = {
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 40
      }
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 40
      }
    }
  };

  const itemVariants = {
    closed: { opacity: 0, y: 20 },
    open: i => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1
      }
    })
  };

  const menuItems = [
    { path: '/', label: 'Inicio' },
    { path: '/shop', label: 'Tienda' },
    { path: '/cart', label: 'Carrito' }
  ];

  return (
    <>
      <MenuButton
        onClick={() => setIsOpen(!isOpen)}
        whileTap={{ scale: 0.95 }}
      >
        <MenuIcon isOpen={isOpen} />
      </MenuButton>

      <AnimatePresence>
        {isOpen && (
          <MenuOverlay
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            {menuItems.map((item, i) => (
              <MenuItem
                key={item.path}
                custom={i}
                variants={itemVariants}
                onClick={() => setIsOpen(false)}
              >
                <MenuLink to={item.path}>
                  {item.label}
                </MenuLink>
              </MenuItem>
            ))}

            <ProfileSection>
              {currentUser ? (
                <>
                  <ProfileButton
                    onClick={() => {
                      navigate('/profile');
                      setIsOpen(false);
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Mi Perfil
                  </ProfileButton>
                  <ProfileButton
                    onClick={handleLogout}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Cerrar Sesión
                  </ProfileButton>
                </>
              ) : (
                <>
                  <ProfileButton
                    onClick={() => {
                      navigate('/login');
                      setIsOpen(false);
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Iniciar Sesión
                  </ProfileButton>
                  <ProfileButton
                    onClick={() => {
                      navigate('/register');
                      setIsOpen(false);
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Registrarse
                  </ProfileButton>
                </>
              )}
            </ProfileSection>
          </MenuOverlay>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu; 