import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import OptimizedImage from '../../components/OptimizedImage';

const ShopContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-top: 0;
`;

const ShopBanner = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  overflow: hidden;
  margin-top: 60px;
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const BannerContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 2rem;
  max-width: 800px;
`;

const ShopTitle = styled(motion.h1)`
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
  letter-spacing: 4px;
  color: #000;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const ShopDescription = styled(motion.p)`
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  letter-spacing: 1px;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 2rem;
`;

const MainCategories = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const CategoryText = styled.span`
  font-size: 1rem;
  color: ${props => props.active ? '#000' : '#666'};
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  padding: 0.5rem 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${props => props.active ? '100%' : '0'};
    height: 1px;
    background-color: #000;
    transition: all 0.3s ease;
  }

  &:hover {
    color: #000;
    &:after {
      width: 100%;
    }
  }
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
`;

const FilterGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;

  &:after {
    content: '▼';
    font-size: 0.8rem;
    color: #666;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 0.75rem 0;
  padding-right: 1.5rem;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  background: transparent;
  font-size: 0.875rem;
  cursor: pointer;
  color: #333;
  outline: none;
  appearance: none;
  transition: all 0.3s ease;
  
  &:hover, &:focus {
    border-bottom-color: #000;
  }
  
  option {
    background: white;
    color: #333;
    padding: 1rem;
  }
`;

const FilterLabel = styled.span`
  position: absolute;
  top: -1rem;
  left: 0;
  font-size: 0.75rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem 0;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

const ProductCard = styled(motion.div)`
  cursor: pointer;
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const ProductImage = styled(OptimizedImage)`
  width: 100%;
  height: 400px;

  @media (max-width: 768px) {
    height: 250px;
  }
`;

const ProductInfo = styled.div`
  padding: 1.5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ProductCategory = styled.span`
  font-size: 0.75rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  display: block;
`;

const ProductName = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
`;

const ProductPrice = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
`;

const NoResults = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  color: #666;
`;

const DiscountBadge = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #000;
  color: white;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 2;
`;

const FeaturedBadge = styled.span`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #000;
  color: white;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 2;
`;

const NoImage = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  color: #6c757d;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    height: 250px;
  }
`;

const OutOfStockBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 2;
`;

const OutOfStockLabel = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #000;
  color: white;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 3;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const CategoryCard = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  aspect-ratio: 1;
  background: #f8f8f8;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CategoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const CategoryOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 100%);
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
`;

const CategoryTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  text-align: center;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
  }
`;

const CATEGORIES = {
  ropa: {
    name: 'Ropa',
    subcategories: ['Camisetas', 'Blusas', 'Jerseys', 'Pantalones', 'Vestidos', 'Faldas', 'Abrigos']
  },
  accesorios: {
    name: 'Accesorios',
    subcategories: ['Bolsos', 'Joyería', 'Cinturones', 'Sombreros', 'Bufandas']
  },
  calzado: {
    name: 'Calzado',
    subcategories: ['Deportivos', 'Tacones', 'Botas', 'Sandalias', 'Mocasines']
  }
};

const SubcategoryText = styled(CategoryText)`
  font-size: 0.9rem;
  color: ${props => props.active ? '#000' : '#888'};
  
  &:after {
    height: 1px;
  }
`;

const FEATURED_CATEGORIES = [
  {
    id: 'ropa',
    name: 'Ropa',
    image: 'https://images.unsplash.com/photo-1515372039744-b8f02a3ae446?auto=format&fit=crop&w=800'
  },
  {
    id: 'accesorios',
    name: 'Accesorios',
    image: 'https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?auto=format&fit=crop&w=800'
  },
  {
    id: 'calzado',
    name: 'Calzado',
    image: 'https://images.unsplash.com/photo-1594633312681-425c7b97ccd1?auto=format&fit=crop&w=800'
  }
];

const Shop = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeMainCategory, setActiveMainCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const [sortBy, setSortBy] = useState('newest');
  const [priceRange, setPriceRange] = useState('all');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bannerSettings, setBannerSettings] = useState({
    image: '',
    title: '',
    description: ''
  });

  const searchParams = new URLSearchParams(location.search);
  const categoryFromUrl = searchParams.get('category');

  useEffect(() => {
    fetchProducts();
    fetchBannerSettings();
  }, []);

  useEffect(() => {
    if (categoryFromUrl) {
      console.log('Categoría desde URL:', categoryFromUrl);
      setActiveMainCategory(categoryFromUrl);
      
      const subcategoryFromUrl = searchParams.get('subcategory');
      if (subcategoryFromUrl) {
        console.log('Subcategoría desde URL:', subcategoryFromUrl);
        setActiveSubcategory(subcategoryFromUrl.toLowerCase());
        filterProducts(categoryFromUrl, subcategoryFromUrl);
      } else {
        setActiveSubcategory(null);
        filterProducts(categoryFromUrl, null);
      }
    } else {
      setActiveMainCategory(null);
      setActiveSubcategory(null);
      filterProducts(null, null);
    }
  }, [categoryFromUrl, location.search]);

  const fetchBannerSettings = async () => {
    try {
      const contentRef = doc(db, 'settings', 'content');
      const contentDoc = await getDoc(contentRef);
      
      if (contentDoc.exists()) {
        const data = contentDoc.data();
        if (data.shopBanner) {
          setBannerSettings(data.shopBanner);
        }
      }
    } catch (error) {
      console.error('Error al cargar la configuración del banner:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Obtener categorías únicas
      const uniqueCategories = [...new Set(productsData.map(p => p.category))];
      console.log('Categorías únicas en la base de datos:', uniqueCategories);
      
      setProducts(productsData);
      setFilteredProducts(productsData); // Inicializar los productos filtrados
    } catch (error) {
      console.error('Error al cargar productos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMainCategoryClick = (category) => {
    console.log('Click en categoría principal:', category);
    
    if (category === activeMainCategory) {
      // Si hacemos clic en la categoría activa, la desactivamos
      setActiveMainCategory(null);
      setActiveSubcategory(null);
      navigate('/shop');
      filterProducts(null, null);
    } else {
      // Activamos la nueva categoría
      setActiveMainCategory(category);
      setActiveSubcategory(null);
      navigate(`/shop?category=${category}`);
      filterProducts(category, null);
    }
  };

  const handleSubcategoryClick = (subcategory) => {
    console.log('Click en subcategoría:', subcategory);
    const newSubcategory = activeSubcategory === subcategory.toLowerCase() ? null : subcategory.toLowerCase();
    setActiveSubcategory(newSubcategory);
    
    // Actualizamos la URL solo si cambiamos la subcategoría
    if (newSubcategory) {
      navigate(`/shop?category=${activeMainCategory}&subcategory=${newSubcategory}`);
    } else {
      navigate(`/shop?category=${activeMainCategory}`);
    }
    
    filterProducts(activeMainCategory, newSubcategory);
  };

  const filterProducts = (category = activeMainCategory, subcategory = activeSubcategory) => {
    console.log('Filtrando productos:', { category, subcategory });
    
    let filtered = products;

    if (category) {
      filtered = filtered.filter(product => 
        product.category?.toLowerCase() === category.toLowerCase()
      );
    }

    if (subcategory) {
      filtered = filtered.filter(product => 
        product.subcategory?.toLowerCase() === subcategory.toLowerCase()
      );
    }

    // Aplicar filtro de precio si está seleccionado
    if (priceRange !== 'all') {
      const [min, max] = priceRange.split('-').map(Number);
      filtered = filtered.filter(product => {
        const price = product.price * (1 - (product.discount || 0) / 100);
        if (!max) {
          return price >= min;
        }
        return price >= min && price <= max;
      });
    }

    // Ordenar productos según el criterio seleccionado
    filtered.sort((a, b) => {
      const priceA = a.price * (1 - (a.discount || 0) / 100);
      const priceB = b.price * (1 - (b.discount || 0) / 100);

      switch (sortBy) {
        case 'price-asc':
          return priceA - priceB;
        case 'price-desc':
          return priceB - priceA;
        case 'name-asc':
          return a.name.localeCompare(b.name);
        case 'name-desc':
          return b.name.localeCompare(a.name);
        case 'newest':
        default:
          const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
          const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
          return dateB - dateA;
      }
    });

    console.log('Productos filtrados:', filtered.length);
    setFilteredProducts(filtered);
  };

  return (
    <ShopContainer>
      {bannerSettings.image && bannerSettings.title && (
        <ShopBanner backgroundImage={bannerSettings.image}>
          <BannerContent>
            <ShopTitle
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              {bannerSettings.title}
            </ShopTitle>
            {bannerSettings.description && (
              <ShopDescription
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                {bannerSettings.description}
              </ShopDescription>
            )}
          </BannerContent>
        </ShopBanner>
      )}

      <ContentContainer>
        <FiltersSection>
          <MainCategories>
            <CategoryText 
              active={!activeMainCategory}
              onClick={() => {
                setActiveMainCategory(null);
                setActiveSubcategory(null);
                navigate('/shop');
              }}
            >
              Todo
            </CategoryText>
            {FEATURED_CATEGORIES.map((category) => (
              <CategoryText
                key={category.id}
                active={activeMainCategory === category.id}
                onClick={() => handleMainCategoryClick(category.id)}
              >
                {category.name}
              </CategoryText>
            ))}
          </MainCategories>

          <AnimatePresence>
            {activeMainCategory && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <FilterRow>
                  {CATEGORIES[activeMainCategory]?.subcategories.map((subcategory) => (
                    <SubcategoryText
                      key={subcategory}
                      active={activeSubcategory === subcategory.toLowerCase()}
                      onClick={() => handleSubcategoryClick(subcategory)}
                    >
                      {subcategory}
                    </SubcategoryText>
                  ))}
                </FilterRow>
              </motion.div>
            )}
          </AnimatePresence>

          <FilterRow>
            <FilterGroup>
              <FilterLabel>Ordenar por</FilterLabel>
              <FilterSelect 
                value={sortBy} 
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="newest">Más recientes</option>
                <option value="price-asc">Precio: Menor a Mayor</option>
                <option value="price-desc">Precio: Mayor a Menor</option>
                <option value="name-asc">Nombre: A-Z</option>
                <option value="name-desc">Nombre: Z-A</option>
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Rango de precio</FilterLabel>
              <FilterSelect 
                value={priceRange} 
                onChange={(e) => setPriceRange(e.target.value)}
              >
                <option value="all">Todos los precios</option>
                <option value="0-50">Hasta 50€</option>
                <option value="50-100">50€ - 100€</option>
                <option value="100-200">100€ - 200€</option>
                <option value="200-">Más de 200€</option>
              </FilterSelect>
            </FilterGroup>
          </FilterRow>
        </FiltersSection>

        <AnimatePresence mode="wait">
          {loading ? (
            <NoResults>Cargando productos...</NoResults>
          ) : filteredProducts.length > 0 ? (
            <ProductsGrid>
              {filteredProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  onClick={() => navigate(`/product/${product.id}`)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  whileHover={{ y: product.stock > 0 ? -10 : 0 }}
                  transition={{ duration: 0.3 }}
                  style={{ 
                    cursor: product.stock > 0 ? 'pointer' : 'not-allowed',
                    opacity: product.stock > 0 ? 1 : 0.8 
                  }}
                >
                  {product.featured && <FeaturedBadge>Destacado</FeaturedBadge>}
                  {product.discount > 0 && (
                    <DiscountBadge>{product.discount}% OFF</DiscountBadge>
                  )}
                  {product.stock <= 0 && <OutOfStockLabel>Sin Stock</OutOfStockLabel>}
                  <div style={{ position: 'relative' }}>
                    {product.images && Array.isArray(product.images) && product.images.length > 0 ? (
                      <ProductImage 
                        src={product.images[0]} 
                        alt={product.name}
                      />
                    ) : (
                      <NoImage>Sin imagen disponible</NoImage>
                    )}
                    {product.stock <= 0 && (
                      <OutOfStockBanner>
                        Agotado
                      </OutOfStockBanner>
                    )}
                  </div>
                  <ProductInfo>
                    <ProductCategory>
                      {FEATURED_CATEGORIES.find(cat => cat.id === product.category)?.name || product.category}
                    </ProductCategory>
                    <ProductName>{product.name}</ProductName>
                    <ProductPrice>
                      {product.discount > 0 ? (
                        <>
                          <span style={{ 
                            textDecoration: 'line-through', 
                            color: '#999', 
                            marginRight: '0.5rem',
                            fontSize: '0.9em'
                          }}>
                            €{product.price.toFixed(2)}
                          </span>
                          €{(product.price * (1 - product.discount / 100)).toFixed(2)}
                        </>
                      ) : (
                        `€${product.price.toFixed(2)}`
                      )}
                    </ProductPrice>
                  </ProductInfo>
                </ProductCard>
              ))}
            </ProductsGrid>
          ) : (
            <NoResults>
              No se encontraron productos en esta categoría
            </NoResults>
          )}
        </AnimatePresence>

        <CategoriesGrid>
          {FEATURED_CATEGORIES.map((category) => (
            <CategoryCard
              key={category.id}
              onClick={() => handleMainCategoryClick(category.id)}
            >
              <CategoryImage
                src={category.image}
                alt={category.name}
                loading={category.id === 'vestidos' ? 'eager' : 'lazy'}
              />
              <CategoryOverlay>
                <CategoryTitle>{category.name}</CategoryTitle>
              </CategoryOverlay>
            </CategoryCard>
          ))}
        </CategoriesGrid>
      </ContentContainer>
    </ShopContainer>
  );
};

export default Shop; 