import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height || 'auto'};
  background-color: #f8f9fa;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${props => props.objectFit || 'cover'};
  transition: opacity 0.3s ease;
  opacity: ${props => props.isLoaded ? 1 : 0};
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.9rem;
  opacity: ${props => props.isLoading ? 1 : 0};
  transition: opacity 0.3s ease;
`;

const OptimizedImage = ({ 
  src = '',
  alt = '',
  height,
  objectFit = 'cover',
  className,
  style,
  onClick,
  active,
  onLoad: externalOnLoad,
  onError: externalOnError,
  threshold = 0.1, // Porcentaje de visibilidad necesario para cargar
  rootMargin = '50px' // Margen adicional para precargar
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldLoad, setShouldLoad] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    console.log('OptimizedImage - src recibido:', src);
    setIsLoaded(false);
    setHasError(false);
    setErrorMessage('');
    setShouldLoad(false);
  }, [src]);

  useEffect(() => {
    let observer;
    const currentImageRef = imageRef.current;

    if (currentImageRef && !shouldLoad) {
      observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            setShouldLoad(true);
            observer.unobserve(currentImageRef);
          }
        },
        {
          threshold,
          rootMargin
        }
      );

      observer.observe(currentImageRef);
    }

    return () => {
      if (observer && currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, [threshold, rootMargin]);

  const handleLoad = (e) => {
    console.log('Imagen cargada correctamente:', src);
    setIsLoaded(true);
    setHasError(false);
    setErrorMessage('');
    if (externalOnLoad) externalOnLoad(e);
  };

  const handleError = (e) => {
    console.error('Error al cargar la imagen:', src, e);
    setHasError(true);
    setIsLoaded(false);
    setErrorMessage('Error al cargar la imagen');
    if (externalOnError) externalOnError(e);
  };

  const handleClick = (e) => {
    console.log('Imagen clickeada:', src);
    if (onClick) onClick(e);
  };

  if (!src) {
    return (
      <ImageContainer height={height} className={className} style={style}>
        <Placeholder isLoading={true}>
          No hay imagen disponible
        </Placeholder>
      </ImageContainer>
    );
  }

  // Procesar URL de Firebase
  let imageUrl = src;
  if (src.includes('firebasestorage.googleapis.com')) {
    // Verificar si la URL ya tiene parámetros
    imageUrl = src.includes('?') ? src : `${src}?alt=media`;
    console.log('URL de Firebase procesada:', imageUrl);
  }

  return (
    <ImageContainer 
      ref={imageRef}
      height={height} 
      className={className} 
      style={style}
      onClick={handleClick}
    >
      {shouldLoad && (
        <StyledImage
          src={imageUrl}
          alt={alt}
          objectFit={objectFit}
          isLoaded={isLoaded}
          onLoad={handleLoad}
          onError={handleError}
          loading="lazy"
        />
      )}
      <Placeholder isLoading={!isLoaded || hasError}>
        {hasError ? errorMessage : 'Cargando...'}
      </Placeholder>
    </ImageContainer>
  );
};

export default OptimizedImage; 