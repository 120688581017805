import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { optimizeImage } from '../../utils/imageUtils';

const CATEGORIES = {
  ropa: {
    name: 'Ropa',
    subcategories: ['Camisetas', 'Blusas', 'Jerseys', 'Pantalones', 'Vestidos', 'Faldas', 'Abrigos']
  },
  accesorios: {
    name: 'Accesorios',
    subcategories: ['Bolsos', 'Joyería', 'Cinturones', 'Sombreros', 'Bufandas']
  },
  zapatos: {
    name: 'Zapatos',
    subcategories: ['Deportivos', 'Tacones', 'Botas', 'Sandalias', 'Mocasines']
  },
  bebidas: {
    name: 'Bebidas',
    subcategories: ['Refrescos', 'Agua', 'Zumos', 'Energéticas', 'Alcohólicas']
  }
};

const SUBCATEGORIES = {
  ropa: ['Camisetas', 'Blusas', 'Jerseys', 'Pantalones', 'Vestidos', 'Faldas', 'Abrigos'],
  accesorios: ['Bolsos', 'Joyería', 'Cinturones', 'Sombreros', 'Bufandas'],
  zapatos: ['Deportivos', 'Tacones', 'Botas', 'Sandalias', 'Mocasines']
};

const SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '34', '36', '38', '40', '42', '44', '46', '48'];
const COLORS = ['Negro', 'Blanco', 'Rojo', 'Azul', 'Verde', 'Amarillo', 'Rosa', 'Morado', 'Gris', 'Beige', 'Marrón'];
const PATTERNS = ['Liso', 'Estampado', 'Rayas', 'Cuadros', 'Flores', 'Lunares'];

const ORDER_STATUSES = {
  pending: 'Pendiente',
  processing: 'En proceso',
  shipped: 'Enviado',
  delivered: 'Entregado',
  cancelled: 'Cancelado'
};

const SettingsSection = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-bottom: 2rem;
`;

const SettingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const SettingLabel = styled.div`
  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
  }

  p {
    margin: 0.5rem 0 0;
    font-size: 0.875rem;
    color: #666;
  }
`;

const SettingControl = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SettingInput = styled.input`
  width: 100px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.875rem;

  &:focus {
    outline: none;
    border-color: #2563eb;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  box-shadow: 
    0 2px 4px rgba(37, 99, 235, 0.1),
    0 4px 8px rgba(37, 99, 235, 0.1);

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(135deg, #1d4ed8, #1e40af);
    box-shadow: 
      0 4px 8px rgba(37, 99, 235, 0.2),
      0 6px 12px rgba(37, 99, 235, 0.15);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #e5e7eb;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const StatTitle = styled.h3`
  margin: 0;
  font-size: 0.875rem;
  color: #6b7280;
  font-weight: 500;
`;

const StatValue = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
`;

const TopProductsList = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-top: 2rem;
`;

const TopProductItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }
`;

const ProductRank = styled.span`
  width: 24px;
  height: 24px;
  background: ${props => props.rank === 1 ? '#3b82f6' : props.rank === 2 ? '#6366f1' : '#8b5cf6'};
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  margin-right: 1rem;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h4`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
`;

const ProductStats = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.25rem;
`;

const ProductStat = styled.span`
  font-size: 0.75rem;
  color: #6b7280;
`;

const DateRangeFilter = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;
`;

const DateInput = styled.input`
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 0.875rem;
`;

const ChartContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-top: 2rem;
`;

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('products');
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [stats, setStats] = useState({
    totalOrders: 0,
    totalRevenue: 0,
    averageOrderValue: 0,
    topProducts: [],
    orderStatus: {
      pending: 0,
      processing: 0,
      shipped: 0,
      delivered: 0,
      cancelled: 0
    },
    totalShipping: 0
  });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [shippingSettings, setShippingSettings] = useState({
    freeShippingThreshold: 100,
    baseShippingCost: 4.99
  });
  const [isEditingSettings, setIsEditingSettings] = useState(false);
  const [contentSettings, setContentSettings] = useState({
    heroImage: '',
    heroTitle: '',
    heroSubtitle: '',
    shopBanner: {
      image: '',
      title: 'COLECCIÓN',
      description: 'Descubre nuestra nueva colección de prendas y accesorios diseñados para reflejar tu estilo único'
    },
    categories: [
      { id: 1, title: '', description: '', image: '' },
      { id: 2, title: '', description: '', image: '' },
      { id: 3, title: '', description: '', image: '' }
    ],
    featuredProducts: [
      { id: 1, name: '', price: 0, image: '' },
      { id: 2, name: '', price: 0, image: '' },
      { id: 3, name: '', price: 0, image: '' }
    ]
  });

  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    subcategory: '',
    stock: '',
    images: [],
    featured: false,
    discount: 0,
    sizes: [],
    colors: [],
    patterns: []
  });

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredStats, setFilteredStats] = useState({
    totalOrders: 0,
    totalRevenue: 0,
    averageOrderValue: 0,
    topProducts: [],
    totalShipping: 0
  });

  const [featuredProducts, setFeaturedProducts] = useState({
    mainProducts: [],
    smallProducts: []
  });

  useEffect(() => {
    if (activeTab === 'products') {
      fetchProducts();
    } else if (activeTab === 'orders') {
      fetchOrders();
    } else if (activeTab === 'stats') {
      fetchStats();
    } else if (activeTab === 'content') {
      fetchContentSettings();
    } else if (activeTab === 'featured') {
      fetchFeaturedProducts();
    }
  }, [activeTab]);

  useEffect(() => {
    filterProducts();
  }, [products, selectedCategory, selectedSubcategory, searchTerm]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      
      // Filtrar pedidos por fecha
      const filteredOrders = orders.filter(order => {
        const orderDate = new Date(order.createdAt?.toDate());
        return orderDate >= start && orderDate <= end;
      });

      // Recalcular estadísticas
      const totalOrders = filteredOrders.length;
      const totalRevenue = filteredOrders.reduce((sum, order) => sum + (order.total || 0), 0);
      const averageOrderValue = totalOrders > 0 ? totalRevenue / totalOrders : 0;

      // Productos más vendidos en el período
      const productSales = {};
      filteredOrders.forEach(order => {
        order.items?.forEach(item => {
          if (!productSales[item.id]) {
            productSales[item.id] = {
              id: item.id,
              name: item.name || 'Producto sin nombre',
              quantity: 0,
              revenue: 0
            };
          }
          productSales[item.id].quantity += item.quantity || 0;
          productSales[item.id].revenue += (item.price || 0) * (item.quantity || 0);
        });
      });

      const topProducts = Object.values(productSales)
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5);

      setFilteredStats({
        totalOrders,
        totalRevenue,
        averageOrderValue,
        topProducts,
        totalShipping: 0
      });
    } else {
      setFilteredStats({
        totalOrders: stats.totalOrders,
        totalRevenue: stats.totalRevenue,
        averageOrderValue: stats.averageOrderValue,
        topProducts: stats.topProducts,
        totalShipping: stats.totalShipping
      });
    }
  }, [startDate, endDate, orders, stats]);

  const fetchContentSettings = async () => {
    try {
      const contentRef = doc(db, 'settings', 'content');
      const contentDoc = await getDoc(contentRef);
      
      if (contentDoc.exists()) {
        const data = contentDoc.data();
        setContentSettings(prev => ({
          ...prev,
          ...data
        }));
      }
    } catch (error) {
      console.error('Error al cargar la configuración:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error al cargar productos:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'orders'));
      const ordersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOrders(ordersData);
    } catch (error) {
      console.error('Error al cargar pedidos:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      
      // Obtener todas las órdenes
      const ordersSnapshot = await getDocs(collection(db, 'orders'));
      const orders = ordersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Calcular estadísticas
      const totalOrders = orders.length;
      const totalRevenue = orders.reduce((sum, order) => {
        const orderTotal = (order.total || 0);
        const shippingCost = 4.99; // Coste fijo de envío
        return sum + orderTotal;
      }, 0);
      const averageOrderValue = totalOrders > 0 ? totalRevenue / totalOrders : 0;

      // Productos más vendidos
      const productSales = {};
      orders.forEach(order => {
        order.items?.forEach(item => {
          if (!productSales[item.id]) {
            productSales[item.id] = {
              id: item.id,
              name: item.name || 'Producto sin nombre',
              quantity: 0,
              revenue: 0
            };
          }
          productSales[item.id].quantity += item.quantity || 0;
          productSales[item.id].revenue += (item.price || 0) * (item.quantity || 0);
        });
      });

      const topProducts = Object.values(productSales)
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 5);

      // Estados de pedidos
      const orderStatus = {
        pending: orders.filter(order => order.status === 'pending').length,
        processing: orders.filter(order => order.status === 'processing').length,
        shipped: orders.filter(order => order.status === 'shipped').length,
        delivered: orders.filter(order => order.status === 'delivered').length,
        cancelled: orders.filter(order => order.status === 'cancelled').length
      };

      // Calcular total de envíos
      const totalShipping = orders.length * 4.99; // 4.99€ por envío

      setStats({
        totalOrders,
        totalRevenue,
        averageOrderValue,
        topProducts,
        orderStatus,
        totalShipping
      });

    } catch (error) {
      console.error('Error al cargar estadísticas:', error);
      // En caso de error, mantener los valores por defecto
      setStats({
        totalOrders: 0,
        totalRevenue: 0,
        averageOrderValue: 0,
        topProducts: [],
        orderStatus: {
          pending: 0,
          processing: 0,
          shipped: 0,
          delivered: 0,
          cancelled: 0
        },
        totalShipping: 0
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchFeaturedProducts = async () => {
    try {
      const docRef = doc(db, 'settings', 'featuredProducts');
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setFeaturedProducts(docSnap.data());
      }
    } catch (error) {
      console.error('Error al cargar productos destacados:', error);
    }
  };

  const filterProducts = () => {
    let filtered = [...products];

    if (selectedCategory) {
      filtered = filtered.filter(product => product.category === selectedCategory);
    }

    if (selectedSubcategory) {
      filtered = filtered.filter(product => product.subcategory === selectedSubcategory);
    }

    if (searchTerm) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredProducts(filtered);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      if (name === 'sizes' || name === 'colors' || name === 'patterns') {
        setNewProduct(prev => ({
          ...prev,
          [name]: checked 
            ? [...prev[name], value]
            : prev[name].filter(item => item !== value)
        }));
      } else {
        setNewProduct(prev => ({
          ...prev,
          [name]: checked
        }));
      }
    } else {
      setNewProduct(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setNewProduct(prev => ({
      ...prev,
      images: [...prev.images, ...files]
    }));
  };

  const removeImage = (index) => {
    setNewProduct(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setNewProduct({
      ...product,
      images: product.images || [],
      sizes: product.sizes || [],
      colors: product.colors || [],
      patterns: product.patterns || []
    });
    setShowForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log('Iniciando actualización del producto:', editingProduct?.id);
      let imageUrls = [];
      
      if (newProduct.images.length > 0) {
        console.log('Procesando imágenes...');
        const uploadPromises = newProduct.images.map(async (image) => {
          if (image instanceof File) {
            // Optimizar la imagen antes de subirla
            const optimizedImage = await optimizeImage(image);
            const imageRef = ref(storage, `products/${Date.now()}-${optimizedImage.name}`);
            await uploadBytes(imageRef, optimizedImage);
            return getDownloadURL(imageRef);
          }
          return image; // Si no es un archivo, mantener la URL existente
        });
        
        imageUrls = await Promise.all(uploadPromises);
        console.log('URLs de imágenes procesadas:', imageUrls);
      } else if (editingProduct?.images) {
        // Mantener las imágenes existentes si no se agregaron nuevas
        imageUrls = editingProduct.images;
      }

      const productData = {
        name: newProduct.name,
        description: newProduct.description,
        price: Number(newProduct.price),
        category: newProduct.category,
        subcategory: newProduct.subcategory,
        stock: Number(newProduct.stock),
        featured: Boolean(newProduct.featured),
        discount: Number(newProduct.discount) || 0,
        sizes: newProduct.sizes || [],
        colors: newProduct.colors || [],
        patterns: newProduct.patterns || [],
        images: imageUrls,
        updatedAt: new Date()
      };

      console.log('Datos del producto a actualizar:', productData);

      if (editingProduct) {
        // Si estamos editando, actualizar el documento existente
        const productRef = doc(db, 'products', editingProduct.id);
        await updateDoc(productRef, productData);
        console.log('Producto actualizado con éxito');
      } else {
        // Si es nuevo, crear un nuevo documento
        await addDoc(collection(db, 'products'), {
          ...productData,
          createdAt: new Date()
        });
        console.log('Nuevo producto creado con éxito');
      }

      setNewProduct({
        name: '',
        description: '',
        price: '',
        category: '',
        subcategory: '',
        stock: '',
        images: [],
        featured: false,
        discount: 0,
        sizes: [],
        colors: [],
        patterns: []
      });
      
      setEditingProduct(null);
      await fetchProducts(); // Recargar la lista de productos
      setShowForm(false);
      alert(editingProduct ? 'Producto actualizado correctamente' : 'Producto añadido correctamente');
    } catch (error) {
      console.error('Error al guardar el producto:', error);
      toast.error('Error al guardar el producto');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (productId, imageUrls) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este producto?')) {
      try {
        // Eliminar imágenes del storage si existen
        if (imageUrls.length > 0) {
          const deletePromises = imageUrls.map(async (url) => {
            try {
              const imageRef = ref(storage, url);
              await deleteObject(imageRef);
            } catch (error) {
              console.error(`Error al eliminar imagen: ${url}`, error);
            }
          });
          
          await Promise.all(deletePromises);
        }
        
        await deleteDoc(doc(db, 'products', productId));
        fetchProducts();
        alert('Producto eliminado correctamente');
      } catch (error) {
        console.error('Error al eliminar producto:', error);
        alert('Error al eliminar el producto');
      }
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  const handleUpdateOrderStatus = async (orderId, newStatus) => {
    try {
      // Verificar si tenemos la información de envío para estados avanzados
      if ((newStatus === 'shipped' || newStatus === 'delivered') && !selectedOrder.shippingInfo) {
        toast.error('No se puede actualizar el estado sin la información de envío');
        return;
      }

      const orderRef = doc(db, 'orders', orderId);
      await updateDoc(orderRef, {
        status: newStatus,
        updatedAt: serverTimestamp()
      });

      // Actualizar la orden en el estado local
      setOrders(prevOrders =>
        prevOrders.map(order =>
          order.id === orderId
            ? { ...order, status: newStatus }
            : order
        )
      );

      if (selectedOrder) {
        setSelectedOrder(prev => ({ ...prev, status: newStatus }));
      }

      toast.success('Estado del pedido actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el estado del pedido:', error);
      toast.error('Error al actualizar el estado del pedido');
    }
  };

  const handleViewOrderDetails = (order) => {
    setSelectedOrder(order);
    setShowOrderModal(true);
  };

  const createTestOrder = async () => {
    try {
      const testOrder = {
        customerName: "Cliente de Prueba",
        customerEmail: "cliente@test.com",
        shippingAddress: "Calle Test 123",
        phone: "123456789",
        status: "pending",
        items: [
          {
            name: "Coca Cola 1L",
            price: 1.00,
            quantity: 2,
            images: []
          }
        ],
        subtotal: 2.00,
        shipping: 4.99,
        total: 6.99,
        createdAt: new Date(),
        updatedAt: new Date()
      };

      await addDoc(collection(db, 'orders'), testOrder);
      await fetchOrders();
      alert('Pedido de prueba creado correctamente');
    } catch (error) {
      console.error('Error al crear pedido de prueba:', error);
      alert('Error al crear pedido de prueba');
    }
  };

  const handleSaveSettings = async () => {
    try {
      await setDoc(doc(db, 'settings', 'shipping'), shippingSettings);
      setIsEditingSettings(false);
      alert('Configuración guardada correctamente');
    } catch (error) {
      console.error('Error al guardar la configuración:', error);
      alert('Error al guardar la configuración');
    }
  };

  const handleShopBannerChange = async (field, value) => {
    // Solo actualizar el estado local
    setContentSettings(prev => ({
      ...prev,
      shopBanner: {
        ...prev.shopBanner,
        [field]: value
      }
    }));
  };

  const handleContentImageUpload = async (e, section, index) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No se seleccionó ningún archivo');
      return;
    }

    console.log('Archivo seleccionado para subir:', file.name, 'Tamaño:', Math.round(file.size / 1024), 'KB', 'Tipo:', file.type);
    console.log('Usuario actual:', currentUser?.email);

    try {
      // Validar el tamaño del archivo (máximo 5MB)
      if (file.size > 5 * 1024 * 1024) {
        toast.error('La imagen no debe superar los 5MB');
        console.error('Archivo demasiado grande:', Math.round(file.size / 1024), 'KB');
        return;
      }

      // Validar el tipo de archivo
      if (!file.type.startsWith('image/')) {
        toast.error('El archivo debe ser una imagen');
        console.error('Tipo de archivo no válido:', file.type);
        return;
      }

      // Mostrar progreso de carga
      toast.loading('Optimizando y subiendo imagen...', { id: 'uploadToast' });
      console.log('Iniciando optimización de la imagen...');

      try {
        // Optimizar la imagen
        const optimizedFile = await optimizeImage(file);
        console.log('Imagen optimizada correctamente', optimizedFile);
        
        // Crear una referencia única para la imagen
        const fileName = `${section}_${Date.now()}_${optimizedFile.name}`;
        console.log('Nombre del archivo a subir:', fileName);
        const storageRef = ref(storage, `content/${section}/${fileName}`);
        
        try {
          // Subir archivo optimizado
          console.log('Iniciando subida a Firebase Storage...');
          const uploadResult = await uploadBytes(storageRef, optimizedFile);
          console.log('Imagen subida correctamente a Firebase Storage', uploadResult);
          
          try {
            const imageUrl = await getDownloadURL(storageRef);
            console.log('URL de la imagen obtenida:', imageUrl);

            // Actualizar estado local primero
            if (section === 'shopBanner') {
              setContentSettings(prev => ({
                ...prev,
                shopBanner: {
                  ...prev.shopBanner,
                  image: imageUrl
                }
              }));
              
              // Actualización simplificada en Firestore
              try {
                console.log('Intentando actualizar Firestore para shopBanner...');
                // Método simplificado: primero comprobar si existe el documento
                const contentRef = doc(db, 'settings', 'content');
                const docSnap = await getDoc(contentRef);
                
                // Si existe el documento, usar update en lugar de setDoc con merge
                if (docSnap.exists()) {
                  console.log('El documento settings/content existe, actualizando...');
                  // Solo actualizar el campo específico de shopBanner.image
                  await updateDoc(contentRef, {
                    'shopBanner.image': imageUrl
                  });
                } else {
                  console.log('El documento settings/content no existe, creándolo...');
                  // Crear documento completo si no existe
                  await setDoc(contentRef, {
                    shopBanner: {
                      title: contentSettings.shopBanner.title || 'COLECCIÓN',
                      description: contentSettings.shopBanner.description || 'Descubre nuestra nueva colección',
                      image: imageUrl
                    }
                  });
                }
                console.log('Datos actualizados en Firestore para shopBanner');
                toast.success('Imagen subida correctamente', { id: 'uploadToast' });
              } catch (firestoreError) {
                console.error('Error al actualizar Firestore para shopBanner:', firestoreError);
                toast.error('Error al guardar en la base de datos: ' + firestoreError.message, { id: 'uploadToast' });
                return;
              }
            } else {
              // Resto del código para hero y categories (sin cambios)
              // ... (código existente)
            }
          } catch (urlError) {
            console.error('Error al obtener la URL de descarga:', urlError);
            toast.error('Error al obtener la URL de la imagen: ' + urlError.message, { id: 'uploadToast' });
          }
        } catch (uploadError) {
          console.error('Error al subir a Firebase Storage:', uploadError);
          toast.error('Error al subir la imagen al servidor: ' + uploadError.message, { id: 'uploadToast' });
        }
      } catch (optimizeError) {
        console.error('Error al optimizar la imagen:', optimizeError);
        toast.error('Error al procesar la imagen: ' + optimizeError.message, { id: 'uploadToast' });
      }
    } catch (error) {
      console.error('Error general al procesar la imagen:', error);
      toast.error('Error al procesar la imagen: ' + error.message, { id: 'uploadToast' });
    }
  };

  const handleSaveContent = async () => {
    try {
      const docRef = doc(db, 'settings', 'content');
      await setDoc(docRef, contentSettings);
      toast.success('Contenido guardado correctamente');
    } catch (error) {
      console.error('Error al guardar el contenido:', error);
      toast.error('Error al guardar el contenido');
    }
  };

  const handleSaveFeaturedProducts = async () => {
    try {
      await setDoc(doc(db, 'settings', 'featuredProducts'), featuredProducts);
      toast.success('Productos destacados actualizados correctamente');
    } catch (error) {
      console.error('Error al guardar productos destacados:', error);
      toast.error('Error al guardar los productos destacados');
    }
  };

  const handleSelectFeaturedProduct = (product, section, index = null) => {
    setFeaturedProducts(prev => {
      if (section === 'main') {
        const newMainProducts = [...prev.mainProducts];
        newMainProducts[index] = product;
        return { ...prev, mainProducts: newMainProducts };
      } else {
        const newSmallProducts = [...prev.smallProducts];
        newSmallProducts[index] = product;
        return { ...prev, smallProducts: newSmallProducts };
      }
    });
  };

  const handleSaveShopBanner = async () => {
    try {
      const contentRef = doc(db, 'settings', 'content');
      await setDoc(contentRef, {
        shopBanner: contentSettings.shopBanner
      }, { merge: true });
      toast.success('Cambios del banner guardados correctamente');
    } catch (error) {
      console.error('Error al guardar los cambios del banner:', error);
      toast.error('Error al guardar los cambios del banner');
    }
  };

  const renderStats = () => {
    return (
      <div>
        <h2 style={{ marginBottom: '2rem', fontSize: '1.5rem', fontWeight: '600' }}>Resumen de Estadísticas</h2>
        
        <DateRangeFilter>
          <DateInput
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Fecha inicial"
          />
          <span>hasta</span>
          <DateInput
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Fecha final"
          />
        </DateRangeFilter>

        <StatsGrid>
          <StatCard>
            <StatTitle>Total de Pedidos</StatTitle>
            <StatValue>{filteredStats.totalOrders}</StatValue>
          </StatCard>
          
          <StatCard>
            <StatTitle>Ingresos Totales</StatTitle>
            <StatValue>{filteredStats.totalRevenue.toFixed(2)}€</StatValue>
          </StatCard>
          
          <StatCard>
            <StatTitle>Valor Promedio de Pedido</StatTitle>
            <StatValue>{filteredStats.averageOrderValue.toFixed(2)}€</StatValue>
          </StatCard>
          
          <StatCard>
            <StatTitle>Total Envíos</StatTitle>
            <StatValue>{filteredStats.totalShipping.toFixed(2)}€</StatValue>
          </StatCard>

          <StatCard>
            <StatTitle>Pedidos Pendientes</StatTitle>
            <StatValue>{stats.orderStatus.pending}</StatValue>
          </StatCard>

          <StatCard>
            <StatTitle>Tasa de Conversión</StatTitle>
            <StatValue>
              {((filteredStats.totalOrders / (filteredStats.totalOrders + stats.orderStatus.cancelled)) * 100).toFixed(1)}%
            </StatValue>
          </StatCard>
        </StatsGrid>

        <TopProductsList>
          <h3 style={{ margin: '0 0 1.5rem', fontSize: '1rem', fontWeight: '500' }}>Productos Más Vendidos</h3>
          {filteredStats.topProducts.slice(0, 5).map((product, index) => (
            <TopProductItem key={product.id}>
              <ProductRank rank={index + 1}>{index + 1}</ProductRank>
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductStats>
                  <ProductStat>Vendidos: {product.quantity}</ProductStat>
                  <ProductStat>Ingresos: {product.revenue.toFixed(2)}€</ProductStat>
                </ProductStats>
              </ProductInfo>
            </TopProductItem>
          ))}
        </TopProductsList>

        <ChartContainer>
          <h3 style={{ margin: '0 0 1.5rem', fontSize: '1rem', fontWeight: '500' }}>Estado de Pedidos</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
            {Object.entries(ORDER_STATUSES).map(([key, label]) => (
              <div key={key} style={{ flex: 1 }}>
                <div style={{ 
                  fontSize: '1.5rem', 
                  fontWeight: '600',
                  color: key === 'pending' ? '#ffc107' :
                         key === 'processing' ? '#17a2b8' :
                         key === 'shipped' ? '#007bff' :
                         key === 'delivered' ? '#28a745' : '#dc3545'
                }}>
                  {stats.orderStatus[key]}
                </div>
                <div style={{ fontSize: '0.875rem', color: '#6b7280' }}>{label}</div>
              </div>
            ))}
          </div>
        </ChartContainer>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="content-section">
        <div style={{ marginBottom: '2rem' }}>
          <h2 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Gestión de Contenido</h2>
          <p style={{ color: '#666' }}>
            Personaliza el contenido de tu tienda. Los cambios se reflejarán inmediatamente en la web.
          </p>
        </div>

        {/* Hero Section */}
        <div className="content-group">
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginBottom: '1rem'
          }}>
            <h3>Página Principal - Hero</h3>
            <div style={{ 
              padding: '0.5rem 1rem',
              background: '#f8f9fa',
              borderRadius: '4px',
              fontSize: '0.875rem',
              color: '#666'
            }}>
              Sección principal de la página de inicio
            </div>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2rem' }}>
            <div>
              <div className="form-group">
                <label>Imagen Principal</label>
                <div className="image-upload">
                  {contentSettings?.hero?.image ? (
                    <div className="image-preview" style={{ position: 'relative' }}>
                      <img 
                        src={contentSettings.hero.image} 
                        alt="Hero" 
                        style={{ 
                          width: '100%',
                          height: '300px',
                          objectFit: 'cover',
                          borderRadius: '8px'
                        }}
                      />
                      <button
                        type="button"
                        className="remove-image"
                        onClick={() => {
                          setContentSettings(prev => ({
                            ...prev,
                            hero: { ...prev.hero, image: '' }
                          }));
                        }}
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem',
                          background: 'rgba(255, 255, 255, 0.9)',
                          border: 'none',
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          fontSize: '1.2rem'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ) : (
                    <div style={{
                      width: '100%',
                      height: '300px',
                      background: '#f8f9fa',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      border: '2px dashed #dee2e6'
                    }}>
                      <div style={{ textAlign: 'center', color: '#666' }}>
                        <div style={{ marginBottom: '0.5rem' }}>Arrastra una imagen aquí o</div>
                        <label style={{
                          padding: '0.5rem 1rem',
                          background: '#007bff',
                          color: 'white',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }}>
                          Seleccionar Archivo
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleContentImageUpload(e, 'hero')}
                            style={{ display: 'none' }}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  <small style={{ display: 'block', marginTop: '0.5rem', color: '#666' }}>
                    Tamaño recomendado: 1920x1080px
                  </small>
                </div>
              </div>
            </div>

            <div>
              <div className="form-group">
                <label>Título Principal</label>
                <input
                  type="text"
                  value={contentSettings?.hero?.title || ''}
                  onChange={(e) => {
                    setContentSettings(prev => ({
                      ...prev,
                      hero: { ...prev.hero, title: e.target.value }
                    }));
                  }}
                  placeholder="Ej: MALALA"
                  style={{
                    width: '100%',
                    padding: '0.75rem',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    fontSize: '1rem'
                  }}
                />
                <small style={{ display: 'block', marginTop: '0.25rem', color: '#666' }}>
                  Este título aparecerá en grande sobre la imagen principal
                </small>
              </div>

              <div className="form-group" style={{ marginTop: '1.5rem' }}>
                <label>Subtítulo</label>
                <input
                  type="text"
                  value={contentSettings?.hero?.subtitle || ''}
                  onChange={(e) => {
                    setContentSettings(prev => ({
                      ...prev,
                      hero: { ...prev.hero, subtitle: e.target.value }
                    }));
                  }}
                  placeholder="Ej: Today I choose Malala, Today I choose myself"
                  style={{
                    width: '100%',
                    padding: '0.75rem',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    fontSize: '1rem'
                  }}
                />
                <small style={{ display: 'block', marginTop: '0.25rem', color: '#666' }}>
                  Este texto aparecerá debajo del título principal
                </small>
              </div>

              <div style={{ 
                marginTop: '2rem',
                padding: '1rem',
                background: '#f8f9fa',
                borderRadius: '8px'
              }}>
                <h5 style={{ marginBottom: '1rem', color: '#333' }}>Vista Previa</h5>
                <div style={{ 
                  padding: '1rem',
                  background: 'white',
                  borderRadius: '4px',
                  border: '1px solid #dee2e6'
                }}>
                  <div style={{ fontSize: '1.2rem', fontWeight: '500', marginBottom: '0.5rem' }}>
                    {contentSettings?.hero?.title || 'Sin título'}
                  </div>
                  <div style={{ fontSize: '0.9rem', color: '#666' }}>
                    {contentSettings?.hero?.subtitle || 'Sin subtítulo'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Shop Banner Section */}
        <div className="content-group" style={{ marginTop: '3rem', paddingTop: '2rem', borderTop: '1px solid #eee' }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginBottom: '1rem'
          }}>
            <h3>Banner de la Tienda</h3>
            <div style={{ 
              padding: '0.5rem 1rem',
              background: '#f8f9fa',
              borderRadius: '4px',
              fontSize: '0.875rem',
              color: '#666'
            }}>
              Banner principal de la página de tienda
            </div>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2rem' }}>
            <div>
              <div className="form-group">
                <label>Imagen del Banner</label>
                <div className="image-upload">
                  {contentSettings?.shopBanner?.image ? (
                    <div className="image-preview" style={{ position: 'relative' }}>
                      <img 
                        src={contentSettings.shopBanner.image} 
                        alt="Shop Banner" 
                        style={{ 
                          width: '100%',
                          height: '300px',
                          objectFit: 'cover',
                          borderRadius: '8px'
                        }}
                      />
                      <button
                        type="button"
                        className="remove-image"
                        onClick={() => {
                          setContentSettings(prev => ({
                            ...prev,
                            shopBanner: { ...prev.shopBanner, image: '' }
                          }));
                        }}
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem',
                          background: 'rgba(255, 255, 255, 0.9)',
                          border: 'none',
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          fontSize: '1.2rem'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ) : (
                    <div style={{
                      width: '100%',
                      height: '300px',
                      background: '#f8f9fa',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      border: '2px dashed #dee2e6'
                    }}>
                      <div style={{ textAlign: 'center', color: '#666' }}>
                        <div style={{ marginBottom: '0.5rem' }}>Arrastra una imagen aquí o</div>
                        <label style={{
                          padding: '0.5rem 1rem',
                          background: '#007bff',
                          color: 'white',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }}>
                          Seleccionar Archivo
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleContentImageUpload(e, 'shopBanner')}
                            style={{ display: 'none' }}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  <small style={{ display: 'block', marginTop: '0.5rem', color: '#666' }}>
                    Tamaño recomendado: 1920x600px
                  </small>
                </div>
              </div>
            </div>

            <div>
              <div className="form-group" style={{ marginBottom: '1.5rem' }}>
                <label>Título del Banner</label>
                <input
                  type="text"
                  value={contentSettings?.shopBanner?.title || ''}
                  onChange={(e) => handleShopBannerChange('title', e.target.value)}
                  placeholder="Ej: COLECCIÓN"
                  style={{
                    width: '100%',
                    padding: '0.75rem',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    fontSize: '1rem'
                  }}
                />
                <small style={{ display: 'block', marginTop: '0.25rem', color: '#666' }}>
                  Este título aparecerá en grande sobre la imagen del banner
                </small>
              </div>

              <div className="form-group">
                <label>Descripción del Banner</label>
                <textarea
                  value={contentSettings?.shopBanner?.description || ''}
                  onChange={(e) => handleShopBannerChange('description', e.target.value)}
                  placeholder="Describe brevemente la colección..."
                  style={{
                    width: '100%',
                    padding: '0.75rem',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    fontSize: '1rem',
                    minHeight: '100px',
                    resize: 'vertical'
                  }}
                />
                <small style={{ display: 'block', marginTop: '0.25rem', color: '#666' }}>
                  Esta descripción aparecerá debajo del título del banner
                </small>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '2rem', textAlign: 'right' }}>
            <button
              onClick={handleSaveShopBanner}
              style={{
                padding: '0.75rem 1.5rem',
                background: '#28a745',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '0.9rem',
                fontWeight: '500'
              }}
            >
              Guardar Cambios del Banner
            </button>
          </div>
        </div>

        {/* Categorías Destacadas */}
        <div className="content-group">
          <h3>Categorías Destacadas</h3>
          <p style={{ marginBottom: '1rem', color: '#666' }}>
            Estas categorías aparecerán en la página principal
          </p>
          
          {contentSettings?.categories?.map((category, index) => (
            <div key={index} className="category-item">
              <h4>Categoría {index + 1}</h4>
              
              <div className="form-group">
                <label>Título de la Categoría</label>
                <input
                  type="text"
                  placeholder="Ej: Vestidos, Accesorios, etc."
                  value={category.title || ''}
                  onChange={(e) => {
                    const newCategories = [...contentSettings.categories];
                    newCategories[index] = {
                      ...newCategories[index],
                      title: e.target.value
                    };
                    setContentSettings(prev => ({
                      ...prev,
                      categories: newCategories
                    }));
                  }}
                />
              </div>

              <div className="form-group">
                <label>Descripción</label>
                <input
                  type="text"
                  placeholder="Ej: Elegancia para cada ocasión"
                  value={category.description || ''}
                  onChange={(e) => {
                    const newCategories = [...contentSettings.categories];
                    newCategories[index] = {
                      ...newCategories[index],
                      description: e.target.value
                    };
                    setContentSettings(prev => ({
                      ...prev,
                      categories: newCategories
                    }));
                  }}
                />
              </div>

              <div className="form-group">
                <label>Imagen de la Categoría</label>
                <div className="image-upload">
                  {category.image ? (
                    <div className="image-preview" style={{ position: 'relative' }}>
                      <img 
                        src={category.image} 
                        alt={category.title || `Categoría ${index + 1}`}
                        style={{ 
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '8px'
                        }}
                      />
                      <button
                        type="button"
                        className="remove-image"
                        onClick={() => {
                          const newCategories = [...contentSettings.categories];
                          newCategories[index] = {
                            ...newCategories[index],
                            image: ''
                          };
                          setContentSettings(prev => ({
                            ...prev,
                            categories: newCategories
                          }));
                        }}
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem',
                          background: 'rgba(255, 255, 255, 0.9)',
                          border: 'none',
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          fontSize: '1.2rem'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ) : (
                    <div style={{
                      width: '100%',
                      height: '200px',
                      background: '#f8f9fa',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      border: '2px dashed #dee2e6'
                    }}>
                      <div style={{ textAlign: 'center', color: '#666' }}>
                        <div style={{ marginBottom: '0.5rem' }}>Arrastra una imagen aquí o</div>
                        <label style={{
                          padding: '0.5rem 1rem',
                          background: '#007bff',
                          color: 'white',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }}>
                          Seleccionar Archivo
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleContentImageUpload(e, 'categories', index)}
                            style={{ display: 'none' }}
                          />
                        </label>
                      </div>
                    </div>
                  )}
                  <small style={{ display: 'block', marginTop: '0.5rem', color: '#666' }}>
                    Tamaño recomendado: 800x600px
                  </small>
                </div>
              </div>

              {/* Vista previa de la categoría */}
              <div style={{ 
                marginTop: '1rem',
                padding: '1rem',
                background: '#f8f9fa',
                borderRadius: '8px'
              }}>
                <h5 style={{ marginBottom: '0.5rem' }}>Vista Previa</h5>
                <div style={{ fontSize: '0.875rem', color: '#666' }}>
                  <p><strong>Título:</strong> {category.title || 'Sin título'}</p>
                  <p><strong>Descripción:</strong> {category.description || 'Sin descripción'}</p>
                  <p><strong>Imagen:</strong> {category.image ? 'Cargada' : 'No cargada'}</p>
                </div>
              </div>

              {/* Botón para eliminar categoría */}
              {contentSettings.categories.length > 1 && (
                <button
                  type="button"
                  onClick={() => {
                    const newCategories = contentSettings.categories.filter((_, i) => i !== index);
                    setContentSettings(prev => ({
                      ...prev,
                      categories: newCategories
                    }));
                  }}
                  style={{
                    marginTop: '1rem',
                    padding: '0.5rem 1rem',
                    background: '#dc3545',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Eliminar Categoría
                </button>
              )}
            </div>
          ))}

          {/* Botón para agregar nueva categoría */}
          <button
            type="button"
            onClick={() => {
              setContentSettings(prev => ({
                ...prev,
                categories: [
                  ...prev.categories,
                  { title: '', description: '', image: '' }
                ]
              }));
            }}
            style={{
              marginTop: '1rem',
              padding: '1rem',
              background: '#28a745',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              width: '100%'
            }}
          >
            + Agregar Nueva Categoría
          </button>
        </div>

        <div style={{ 
          position: 'sticky', 
          bottom: '2rem', 
          background: 'white', 
          padding: '1rem',
          boxShadow: '0 -4px 12px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          marginTop: '2rem'
        }}>
          <button
            onClick={handleSaveContent}
            style={{
              width: '100%',
              padding: '1rem',
              background: '#28a745',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              fontSize: '1rem',
              fontWeight: '500',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem'
            }}
          >
            <span>Guardar Cambios</span>
            <small style={{ opacity: 0.8 }}>Los cambios se publicarán inmediatamente</small>
          </button>
        </div>
      </div>
    );
  };

  const renderFeaturedProductsSection = () => {
    return (
      <div className="content-section">
        <h2>Productos Destacados - Página de Inicio</h2>
        
        <div className="content-group">
          <h3>Productos Principales (2)</h3>
          <div className="grid-layout">
            {[0, 1].map((index) => (
              <div key={`main-${index}`} className="product-selection">
                <select
                  value={featuredProducts.mainProducts[index]?.id || ''}
                  onChange={(e) => {
                    const product = products.find(p => p.id === e.target.value);
                    handleSelectFeaturedProduct(product, 'main', index);
                  }}
                >
                  <option value="">Seleccionar producto</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name} - {product.price}€
                    </option>
                  ))}
                </select>
                {featuredProducts.mainProducts[index] && (
                  <div className="selected-product">
                    <img 
                      src={featuredProducts.mainProducts[index].images?.[0]} 
                        alt={featuredProducts.mainProducts[index].name}
                    />
                    <p>{featuredProducts.mainProducts[index].name}</p>
                    <p>{featuredProducts.mainProducts[index].price}€</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="content-group">
          <h3>Productos Pequeños (8)</h3>
          <div className="grid-layout">
            {Array.from({ length: 8 }, (_, index) => (
              <div key={`small-${index}`} className="product-selection">
                <select
                  value={featuredProducts.smallProducts[index]?.id || ''}
                  onChange={(e) => {
                    const product = products.find(p => p.id === e.target.value);
                    handleSelectFeaturedProduct(product, 'small', index);
                  }}
                >
                  <option value="">Seleccionar producto</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name} - {product.price}€
                    </option>
                  ))}
                </select>
                {featuredProducts.smallProducts[index] && (
                  <div className="selected-product">
                    <img 
                      src={featuredProducts.smallProducts[index].images?.[0]} 
                        alt={featuredProducts.smallProducts[index].name}
                    />
                    <p>{featuredProducts.smallProducts[index].name}</p>
                    <p>{featuredProducts.smallProducts[index].price}€</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <button className="save-button" onClick={handleSaveFeaturedProducts}>
          Guardar Cambios
        </button>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'products':
        return (
          <>
            <div className="main-header">
              <div className="search-filters">
                <input
                  type="text"
                  placeholder="Buscar productos..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
                <select
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                    setSelectedSubcategory('');
                  }}
                  className="filter-select"
                >
                  <option value="">Todas las categorías</option>
                  {Object.entries(CATEGORIES).map(([id, category]) => (
                    <option key={id} value={id}>{category.name}</option>
                  ))}
                </select>
                {selectedCategory && (
                  <select
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    className="filter-select"
                  >
                    <option value="">Todas las subcategorías</option>
                    {CATEGORIES[selectedCategory].subcategories?.map(sub => (
                      <option key={sub} value={sub}>{sub}</option>
                    ))}
                  </select>
                )}
              </div>
              <button 
                className="add-product-btn"
                onClick={() => {
                  setEditingProduct(null);
                  setNewProduct({
                    name: '',
                    description: '',
                    price: '',
                    category: '',
                    subcategory: '',
                    stock: '',
                    images: [],
                    featured: false,
                    discount: 0,
                    sizes: [],
                    colors: [],
                    patterns: []
                  });
                  setShowForm(true);
                }}
              >
                Añadir Producto
              </button>
            </div>

            <div className="products-grid">
              {loading ? (
                <div className="loading">Cargando...</div>
              ) : filteredProducts.length > 0 ? (
                filteredProducts.map(product => (
                  <div key={product.id} className="product-card">
                    <div className="product-image">
                      {product.images && product.images.length > 0 ? (
                        <img src={product.images[0]} alt={product.name} />
                      ) : (
                        <div className="no-image">Sin imagen</div>
                      )}
                      {product.featured && <span className="featured-badge">Destacado</span>}
                      {product.discount > 0 && (
                        <span className="discount-badge">{product.discount}% OFF</span>
                      )}
                    </div>
                    <div className="product-info">
                      <h3>{product.name}</h3>
                      <p className="product-category">{product.category} - {product.subcategory}</p>
                      <p className="product-description">{product.description}</p>
                      <div className="product-details">
                        <span className="product-price">€{product.price}</span>
                        <span className="product-stock">Stock: {product.stock}</span>
                      </div>
                      <div className="product-actions">
                        <button 
                          className="edit-btn"
                          onClick={() => handleEdit(product)}
                        >
                          Editar
                        </button>
                        <button 
                          className="delete-btn"
                          onClick={() => handleDelete(product.id, product.images || [])}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-products">
                  No se encontraron productos
                </div>
              )}
            </div>
          </>
        );

      case 'orders':
        return (
          <div className="orders-container">
            <div className="orders-header">
              <h2>Pedidos Recientes</h2>
              <button 
                className="add-order-btn"
                onClick={createTestOrder}
              >
                Crear Pedido de Prueba
              </button>
            </div>
            {loading ? (
              <div className="loading">Cargando...</div>
            ) : orders.length > 0 ? (
              <div className="orders-list">
                {orders.map(order => (
                  <div key={order.id} className="order-card">
                    <div className="order-header">
                      <h3>Pedido #{order.id.slice(-6)}</h3>
                      <span className={`order-status status-${order.status}`}>
                        {ORDER_STATUSES[order.status]}
                      </span>
                    </div>
                    <div className="order-details">
                      <p><strong>Cliente:</strong> {order.customerName}</p>
                      <p><strong>Email:</strong> {order.customerEmail}</p>
                      <p><strong>Fecha:</strong> {new Date(order.createdAt?.toDate()).toLocaleDateString()}</p>
                      <p><strong>Total:</strong> €{order.total}</p>
                    </div>
                    <div className="order-items">
                      <h4>Productos:</h4>
                      {order.items.map((item, index) => (
                        <div key={index} className="order-item">
                          <span>{item.name}</span>
                          <span>x{item.quantity}</span>
                          <span>€{item.price}</span>
                        </div>
                      ))}
                    </div>
                    <div className="order-actions">
                      <select
                        value={order.status}
                        onChange={(e) => handleUpdateOrderStatus(order.id, e.target.value)}
                        className="status-select"
                      >
                        {Object.entries(ORDER_STATUSES).map(([value, label]) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                      <button 
                        className="btn-details"
                        onClick={() => handleViewOrderDetails(order)}
                      >
                        Ver Detalles
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-orders">No hay pedidos disponibles</div>
            )}
          </div>
        );

      case 'stats':
        return renderStats();

      case 'content':
        return renderContentSection();

      case 'featured':
        return renderFeaturedProductsSection();

      default:
        return null;
    }
  };

  const renderProductForm = () => {
    return (
      <div className="product-form">
        <h2>{editingProduct ? 'Editar Producto' : 'Nuevo Producto'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nombre del Producto</label>
            <input
              type="text"
              name="name"
              value={newProduct.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Descripción</label>
            <textarea
              name="description"
              value={newProduct.description}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Precio (€)</label>
              <input
                type="number"
                name="price"
                value={newProduct.price}
                onChange={handleInputChange}
                step="0.01"
                min="0"
                required
              />
            </div>

            <div className="form-group">
              <label>Stock</label>
              <input
                type="number"
                name="stock"
                value={newProduct.stock}
                onChange={handleInputChange}
                min="0"
                required
              />
            </div>

            <div className="form-group">
              <label>Descuento (%)</label>
              <input
                type="number"
                name="discount"
                value={newProduct.discount}
                onChange={handleInputChange}
                min="0"
                max="100"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Categoría</label>
              <select
                name="category"
                value={newProduct.category}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleccionar categoría</option>
                {Object.entries(CATEGORIES).map(([id, category]) => (
                  <option key={id} value={id}>{category.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Subcategoría</label>
              <select
                name="subcategory"
                value={newProduct.subcategory}
                onChange={handleInputChange}
                required
                disabled={!newProduct.category}
              >
                <option value="">Seleccionar subcategoría</option>
                {newProduct.category && CATEGORIES[newProduct.category]?.subcategories?.map(sub => (
                  <option key={sub} value={sub}>{sub}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Imágenes del Producto</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              multiple
              className="file-input"
            />
            <div className="image-preview-container">
              {newProduct.images.map((image, index) => (
                <div key={index} className="image-preview">
                  <img
                    src={image instanceof File ? URL.createObjectURL(image) : image}
                    alt={`Vista previa ${index + 1}`}
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="remove-image"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="form-section">
            <label className="section-title">Tallas Disponibles</label>
            <div className="checkbox-group">
              {SIZES.map(size => (
                <label key={size} className="checkbox-label">
                  <input
                    type="checkbox"
                    name="sizes"
                    value={size}
                    checked={(newProduct.sizes || []).includes(size)}
                    onChange={handleInputChange}
                  />
                  {size}
                </label>
              ))}
            </div>
          </div>

          <div className="form-section">
            <label className="section-title">Colores Disponibles</label>
            <div className="checkbox-group">
              {COLORS.map(color => (
                <label key={color} className="checkbox-label">
                  <input
                    type="checkbox"
                    name="colors"
                    value={color}
                    checked={(newProduct.colors || []).includes(color)}
                    onChange={handleInputChange}
                  />
                  {color}
                </label>
              ))}
            </div>
          </div>

          <div className="form-section">
            <label className="section-title">Estampados/Patrones</label>
            <div className="checkbox-group">
              {PATTERNS.map(pattern => (
                <label key={pattern} className="checkbox-label">
                  <input
                    type="checkbox"
                    name="patterns"
                    value={pattern}
                    checked={(newProduct.patterns || []).includes(pattern)}
                    onChange={handleInputChange}
                  />
                  {pattern}
                </label>
              ))}
            </div>
          </div>

          <div className="form-group checkbox-single">
            <label>
              <input
                type="checkbox"
                name="featured"
                checked={newProduct.featured}
                onChange={handleInputChange}
              />
              Destacado
            </label>
          </div>

          <div className="form-actions">
            <button type="submit" className="submit-btn">
              {editingProduct ? 'Guardar Cambios' : 'Crear Producto'}
            </button>
            <button type="button" className="cancel-btn" onClick={() => setShowForm(false)}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderOrderModal = () => {
    if (!selectedOrder) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Detalles del Pedido #{selectedOrder.id.slice(-6)}</h2>
            <button 
              className="close-modal"
              onClick={() => {
                setSelectedOrder(null);
                setShowOrderModal(false);
              }}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="order-info">
              <h3>Información del Cliente</h3>
              <p><strong>Nombre:</strong> {selectedOrder.customerName}</p>
              <p><strong>Email:</strong> {selectedOrder.customerEmail}</p>
              <p><strong>Dirección:</strong> {selectedOrder.shippingAddress}</p>
              <p><strong>Teléfono:</strong> {selectedOrder.phone}</p>
            </div>
            
            <div className="order-items">
              <h3>Productos</h3>
              {selectedOrder.items.map((item, index) => (
                <div key={index} className="order-item-detail">
                  <img src={item.images?.[0]} alt={item.name} className="item-image" />
                  <div className="item-info">
                    <p className="item-name">{item.name}</p>
                    <p className="item-details">
                      Cantidad: {item.quantity} | 
                      {item.size && ` Talla: ${item.size} |`}
                      {item.color && ` Color: ${item.color}`}
                    </p>
                    <p className="item-price">€{(item.price * item.quantity).toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="order-status-update">
              <h3>Actualizar Estado</h3>
              <select
                value={selectedOrder.status}
                onChange={(e) => handleUpdateOrderStatus(selectedOrder.id, e.target.value)}
                className={`status-${selectedOrder.status}`}
              >
                {Object.entries(ORDER_STATUSES).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>

            <div className="order-summary">
              <h3>Resumen</h3>
              <p><strong>Subtotal:</strong> €{selectedOrder.subtotal?.toFixed(2)}</p>
              <p><strong>Envío:</strong> €{selectedOrder.shipping?.toFixed(2)}</p>
              <p className="total"><strong>Total:</strong> €{selectedOrder.total?.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const requestShippingInfo = async (orderId, userEmail) => {
    try {
      // Enviar email al cliente solicitando la información de envío
      await fetch('https://us-central1-malala-421e9.cloudfunctions.net/sendShippingInfoRequest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId,
          userEmail,
          orderLink: `${window.location.origin}/orders/${orderId}/shipping`
        }),
      });

      toast.success('Solicitud de información de envío enviada al cliente');
    } catch (error) {
      console.error('Error al solicitar información de envío:', error);
      toast.error('Error al enviar la solicitud de información de envío');
    }
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <div className="dashboard-sidebar">
        <div className="sidebar-header">
          <h2>MALALA</h2>
          <p>Panel de Administración</p>
        </div>
        
        <nav className="sidebar-nav">
          <button 
            className={`nav-item ${activeTab === 'products' ? 'active' : ''}`}
            onClick={() => setActiveTab('products')}
          >
            Productos
          </button>
          <button 
            className={`nav-item ${activeTab === 'orders' ? 'active' : ''}`}
            onClick={() => setActiveTab('orders')}
          >
            Pedidos
          </button>
          <button 
            className={`nav-item ${activeTab === 'stats' ? 'active' : ''}`}
            onClick={() => setActiveTab('stats')}
          >
            Estadísticas
          </button>
          <button 
            className={`nav-item ${activeTab === 'content' ? 'active' : ''}`}
            onClick={() => setActiveTab('content')}
          >
            Contenido
          </button>
          <button 
            className={`nav-item ${activeTab === 'featured' ? 'active' : ''}`}
            onClick={() => setActiveTab('featured')}
          >
            Destacados
          </button>
        </nav>

        <div className="sidebar-footer">
          <p>{currentUser?.email}</p>
          <button onClick={handleLogout} className="logout-btn">
            Cerrar Sesión
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="dashboard-main">
        {renderContent()}
      </div>

      {/* Add/Edit Product Modal */}
      {showForm && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{editingProduct ? 'Editar Producto' : 'Añadir Nuevo Producto'}</h2>
              <button 
                className="close-modal"
                onClick={() => {
                  setShowForm(false);
                  setEditingProduct(null);
                }}
              >
                ×
              </button>
            </div>
            {renderProductForm()}
          </div>
        </div>
      )}

      {showOrderModal && renderOrderModal()}
    </div>
  );
};

export default Dashboard; 