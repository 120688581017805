import React, { createContext, useContext, useReducer, useEffect } from 'react';

const CartContext = createContext();

const initialState = {
  cart: [],
  isOpen: false,
  loading: false,
  error: null
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      if (!action.payload?.id || !action.payload?.name || !action.payload?.price) {
        console.error('Invalid item data:', action.payload);
        return state;
      }

      const existingItemIndex = state.cart.findIndex(
        item => 
          item.id === action.payload.id && 
          item.size === action.payload.size && 
          item.color === action.payload.color
      );

      if (existingItemIndex > -1) {
        const newCart = [...state.cart];
        newCart[existingItemIndex].quantity += action.payload.quantity || 1;
        return {
          ...state,
          cart: newCart
        };
      }

      // Asegurarse de que las imágenes sean un array
      const images = Array.isArray(action.payload.images) 
        ? action.payload.images 
        : action.payload.images 
          ? [action.payload.images]
          : [];

      const newItem = {
        id: action.payload.id,
        name: action.payload.name,
        price: action.payload.price,
        discount: action.payload.discount || 0,
        images: images,
        size: action.payload.size || '',
        color: action.payload.color || '',
        quantity: action.payload.quantity || 1
      };

      // Calcular el precio con descuento
      if (newItem.discount > 0) {
        newItem.discountedPrice = newItem.price * (1 - newItem.discount / 100);
      }

      return {
        ...state,
        cart: [...state.cart, newItem]
      };
    }

    case 'UPDATE_QUANTITY':
      return {
        ...state,
        cart: state.cart.map(item => 
          item.id === action.payload.id &&
          item.size === action.payload.size &&
          item.color === action.payload.color
            ? { ...item, quantity: action.payload.quantity }
            : item
        )
      };

    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cart: state.cart.filter(item => 
          !(item.id === action.payload.id &&
            item.size === action.payload.size &&
            item.color === action.payload.color)
        )
      };

    case 'CLEAR_CART':
      return {
        ...state,
        cart: []
      };

    case 'TOGGLE_CART':
      return {
        ...state,
        isOpen: !state.isOpen
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };

    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  // Cargar carrito del localStorage al iniciar
  useEffect(() => {
    try {
      const savedCart = localStorage.getItem('cart');
      if (savedCart) {
        const parsedCart = JSON.parse(savedCart);
        // Asegurarse de que cada item tenga un array de imágenes
        const validatedCart = parsedCart.map(item => ({
          ...item,
          images: Array.isArray(item.images) ? item.images : item.images ? [item.images] : []
        }));
        dispatch({ type: 'ADD_TO_CART', payload: validatedCart });
      }
    } catch (error) {
      console.error('Error loading cart from localStorage:', error);
    }
  }, []);

  // Guardar carrito en localStorage cuando cambie
  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(state.cart));
    } catch (error) {
      console.error('Error saving cart to localStorage:', error);
    }
  }, [state.cart]);

  const addToCart = (product) => {
    // Asegurarse de que las imágenes sean un array antes de agregar al carrito
    const validatedProduct = {
      ...product,
      images: Array.isArray(product.images) ? product.images : product.images ? [product.images] : []
    };
    dispatch({ type: 'ADD_TO_CART', payload: validatedProduct });
  };

  const updateQuantity = (product, quantity) => {
    dispatch({
      type: 'UPDATE_QUANTITY',
      payload: { ...product, quantity }
    });
  };

  const removeFromCart = (product) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: product });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  const toggleCart = () => {
    dispatch({ type: 'TOGGLE_CART' });
  };

  const calculateItemPrice = (item) => {
    if (item.discount > 0) {
      return item.price * (1 - item.discount / 100);
    }
    return item.price;
  };

  const calculateSubtotal = () => {
    return state.cart.reduce((total, item) => {
      const itemPrice = calculateItemPrice(item);
      return total + (itemPrice * item.quantity);
    }, 0);
  };

  const value = {
    cart: state.cart,
    isOpen: state.isOpen,
    loading: state.loading,
    error: state.error,
    addToCart,
    updateQuantity,
    removeFromCart,
    clearCart,
    toggleCart,
    calculateSubtotal,
    calculateItemPrice
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}; 