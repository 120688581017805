import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import { db } from '../../config/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { SHIPPING_COST } from '../../config/constants';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import ShippingForm from './ShippingForm';

const FormContainer = styled.form`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
`;

const PaymentContainer = styled.div`
  margin-bottom: 2rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background: #000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #333;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const Step = styled.div`
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  background: ${props => props.active ? '#000' : '#eee'};
  color: ${props => props.active ? '#fff' : '#666'};
  border-radius: 4px;
  font-size: 0.9rem;
`;

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { cart, clearCart, calculateSubtotal } = useCart();
  const [isProcessing, setIsProcessing] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(null);
  const [step, setStep] = useState(1); // 1: Shipping, 2: Payment

  const handleShippingSubmit = (data) => {
    setShippingInfo(data);
    setStep(2);
  };

  const createOrder = async (paymentIntentId, status = 'pending') => {
    try {
      const orderData = {
        items: cart,
        total: calculateSubtotal() + SHIPPING_COST,
        shippingCost: SHIPPING_COST,
        shippingInfo,
        customerInfo: {
          name: shippingInfo.fullName,
          email: shippingInfo.email,
          phone: shippingInfo.phone,
          address: shippingInfo.address,
          city: shippingInfo.city,
          postalCode: shippingInfo.postalCode,
          country: 'ES'
        },
        status: status,
        paymentStatus: status === 'completed' ? 'paid' : 'processing',
        paymentIntentId,
        createdAt: new Date(),
        updatedAt: new Date()
      };

      const orderRef = await addDoc(collection(db, 'orders'), orderData);
      return orderRef.id;
    } catch (error) {
      console.error('Error al crear la orden:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !shippingInfo) {
      return;
    }

    setIsProcessing(true);
    const loadingToast = toast.loading('Procesando tu pago...');

    try {
      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
          payment_method_data: {
            billing_details: {
              name: shippingInfo.fullName,
              email: shippingInfo.email,
              address: {
                line1: shippingInfo.address,
                city: shippingInfo.city,
                postal_code: shippingInfo.postalCode,
                country: 'ES',
              },
              phone: shippingInfo.phone,
            },
          },
        },
      });

      if (paymentError) {
        toast.dismiss(loadingToast);
        // Manejar errores específicos de Stripe
        switch (paymentError.type) {
          case 'card_error':
            toast.error('Error en la tarjeta: ' + paymentError.message);
            break;
          case 'validation_error':
            toast.error('Por favor, verifica los datos de tu tarjeta');
            break;
          default:
            toast.error('Error al procesar el pago: ' + paymentError.message);
        }
        return;
      }

      // Si el pago fue exitoso, crear la orden
      if (paymentIntent.status === 'succeeded') {
        const orderId = await createOrder(paymentIntent.id, 'completed');
        
        // Actualizar el paymentIntent con el orderId
        await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/payment-success`,
            metadata: {
              orderId: orderId
            }
          }
        });

        clearCart();
        toast.dismiss(loadingToast);
        toast.success('¡Pago completado con éxito!');
        navigate('/payment-success');
      } else {
        toast.dismiss(loadingToast);
        toast.error('El pago no pudo ser completado. Por favor, intenta de nuevo.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.dismiss(loadingToast);
      toast.error('Error al procesar el pago. Por favor, intenta de nuevo.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <StepIndicator>
        <Step active={step === 1}>Envío</Step>
        <Step active={step === 2}>Pago</Step>
      </StepIndicator>

      {step === 1 ? (
        <ShippingForm onSubmit={handleShippingSubmit} />
      ) : (
        <FormContainer onSubmit={handleSubmit}>
          <Title>Información de pago</Title>
          <PaymentContainer>
            <PaymentElement />
          </PaymentContainer>
          <Button disabled={!stripe || isProcessing}>
            {isProcessing ? 'Procesando...' : 'Pagar ahora'}
          </Button>
        </FormContainer>
      )}
    </>
  );
};

export default CheckoutForm; 