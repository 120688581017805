import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useCart } from '../../contexts/CartContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { toast } from 'react-hot-toast';
import OptimizedImage from '../../components/OptimizedImage';

const ProductDetailContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${props => props.theme.spacing.xl};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.xxl};
  background: white;
  padding-top: 100px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    padding: ${props => props.theme.spacing.lg};
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: ${props => props.theme.spacing.md};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const ThumbnailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.sm};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: ${props => props.theme.spacing.sm};
  }
`;

const Thumbnail = styled(OptimizedImage)`
  width: 100px;
  height: 100px;
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.6};
  transition: opacity ${props => props.theme.transitions.fast};
  border: 2px solid ${props => props.active ? '#000' : 'transparent'};

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 80px;
    height: 80px;
  }
`;

const MainImage = styled(OptimizedImage)`
  width: 100%;
  height: 600px;
  transition: opacity 0.3s ease;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 400px;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.lg};
`;

const ProductName = styled.h1`
  font-size: ${props => props.theme.typography.fontSize.xxl};
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  margin-bottom: ${props => props.theme.spacing.xs};
`;

const ProductPrice = styled.p`
  font-size: ${props => props.theme.typography.fontSize.xl};
  color: #000;
  font-weight: 500;
`;

const ProductDescription = styled.p`
  font-size: ${props => props.theme.typography.fontSize.md};
  line-height: 1.6;
  color: ${props => props.theme.colors.text.primary};
`;

const SelectGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.sm};
  margin-bottom: 2rem;
`;

const Label = styled.label`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.text.secondary};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  top: -1rem;
  left: 0;
`;

const Select = styled.select`
  width: 100%;
  max-width: 300px;
  padding: 0.75rem 0;
  padding-right: 1.5rem;
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.border.light};
  font-size: 0.875rem;
  background: transparent;
  cursor: pointer;
  color: ${props => props.theme.colors.text.primary};
  outline: none;
  appearance: none;
  transition: all 0.3s ease;
  
  &:hover, &:focus {
    border-bottom-color: ${props => props.theme.colors.primary.main};
  }

  option {
    background: white;
    color: ${props => props.theme.colors.text.primary};
    padding: 1rem;
  }

  &:after {
    content: '▼';
    font-size: 0.8rem;
    color: ${props => props.theme.colors.text.secondary};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const OutOfStockBadge = styled.div`
  background: #000;
  color: white;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.85rem;
`;

const AddToCartButton = styled.button`
  background: ${props => props.disabled ? '#e0e0e0' : '#000'};
  color: ${props => props.disabled ? '#666' : '#fff'};
  border: none;
  padding: 1rem 2rem;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 500;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  max-width: 400px;

  &:hover {
    background: ${props => props.disabled ? '#e0e0e0' : '#333'};
    transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
  }

  &:active {
    transform: ${props => props.disabled ? 'none' : 'translateY(0)'};
  }
`;

const StockInfo = styled.p`
  font-size: 0.875rem;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
`;

const PatternBadge = styled.span`
  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin: 0.25rem;
  background: #f8f9fa;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  font-size: 0.75rem;
  color: #666;
`;

const PatternSection = styled.div`
  margin-top: 1rem;
`;

const ProductActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.sm};
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
`;

const SuccessMessage = styled.p`
  color: #28a745;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
`;

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedImage, setSelectedImage] = useState(0);
  const [showAddedMessage, setShowAddedMessage] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, 'products', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const productData = {
            id: docSnap.id,
            ...docSnap.data()
          };
          console.log('Producto cargado:', productData);
          console.log('Imágenes del producto:', productData.images);
          setProduct(productData);
        } else {
          setError('Producto no encontrado');
          navigate('/shop');
        }
      } catch (error) {
        console.error('Error al cargar el producto:', error);
        setError('Error al cargar el producto');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id, navigate]);

  const handleImageClick = (index) => {
    console.log('Cambiando a imagen:', index);
    console.log('URL de la imagen seleccionada:', product?.images?.[index]);
    setSelectedImage(index);
  };

  const handleAddToCart = () => {
    if (product.stock <= 0) {
      setErrorMessage('Este producto está agotado');
      setSuccessMessage('');
      return;
    }

    if (product.sizes && product.sizes.length > 0 && !selectedSize) {
      setErrorMessage('Por favor selecciona una talla');
      setSuccessMessage('');
      return;
    }

    if (product.colors && product.colors.length > 0 && !selectedColor) {
      setErrorMessage('Por favor selecciona un color');
      setSuccessMessage('');
      return;
    }

    const cartItem = {
      id: product.id,
      name: product.name,
      price: product.price,
      discount: product.discount || 0,
      images: product.images || [],
      size: selectedSize,
      color: selectedColor,
      quantity: 1
    };

    addToCart(cartItem);
    setSuccessMessage('Producto añadido al carrito');
    setErrorMessage('');
    
    // Emitir evento para abrir el carrito
    const event = new CustomEvent('openCart');
    window.dispatchEvent(event);
  };

  if (loading) {
    return (
      <ProductDetailContainer>
        <div>Cargando producto...</div>
      </ProductDetailContainer>
    );
  }

  if (error || !product) {
    return (
      <ProductDetailContainer>
        <div>{error || 'Producto no encontrado'}</div>
      </ProductDetailContainer>
    );
  }

  return (
    <ProductDetailContainer>
      <Gallery>
        <ThumbnailList>
          {product?.images && product.images.length > 0 ? (
            product.images.map((image, index) => {
              console.log(`Renderizando miniatura ${index}:`, image);
              return (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Thumbnail
                    src={image}
                    alt={`${product.name} - Vista ${index + 1}`}
                    active={selectedImage === index}
                    onClick={() => handleImageClick(index)}
                    loading={index === 0 ? "eager" : "lazy"}
                  />
                </motion.div>
              );
            })
          ) : (
            <Thumbnail
              src={product?.imageUrl}
              alt={product?.name}
              active={true}
            />
          )}
        </ThumbnailList>
        <motion.div
          key={selectedImage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <MainImage
            src={product?.images?.[selectedImage] || product?.imageUrl}
            alt={product?.name}
            loading="eager"
          />
        </motion.div>
      </Gallery>

      <ProductInfo>
        <div>
          <ProductName>{product.name}</ProductName>
          <ProductPrice>
            {product.discount > 0 ? (
              <>
                <span style={{ 
                  textDecoration: 'line-through', 
                  color: '#999', 
                  marginRight: '0.5rem',
                  fontSize: '0.9em'
                }}>
                  €{product.price.toFixed(2)}
                </span>
                €{(product.price * (1 - product.discount / 100)).toFixed(2)}
              </>
            ) : (
              `€${product.price.toFixed(2)}`
            )}
          </ProductPrice>
        </div>

        <ProductDescription>{product.description}</ProductDescription>

        {product.patterns && product.patterns.length > 0 && (
          <PatternSection>
            <Label>Estampado/Patrón</Label>
            <div>
              {product.patterns.map(pattern => (
                <PatternBadge key={pattern}>{pattern}</PatternBadge>
              ))}
            </div>
          </PatternSection>
        )}

        {product.sizes && product.sizes.length > 0 && (
          <SelectGroup>
            <Label>Talla</Label>
            <Select 
              value={selectedSize} 
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              <option value="">Seleccionar talla</option>
              {product.sizes.map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </Select>
          </SelectGroup>
        )}

        {product.colors && product.colors.length > 0 && (
          <SelectGroup>
            <Label>Color</Label>
            <Select 
              value={selectedColor} 
              onChange={(e) => setSelectedColor(e.target.value)}
            >
              <option value="">Seleccionar color</option>
              {product.colors.map(color => (
                <option key={color} value={color}>{color}</option>
              ))}
            </Select>
          </SelectGroup>
        )}

        <ProductActions>
          {product.stock <= 0 && (
            <OutOfStockBadge>
              Producto Agotado
            </OutOfStockBadge>
          )}
          <AddToCartButton 
            onClick={handleAddToCart}
            disabled={product.stock <= 0}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {product.stock <= 0 ? 'Sin Stock' : 'Agregar al Carrito'}
          </AddToCartButton>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
          <StockInfo>
            {product.stock > 0 
              ? `${product.stock} unidades disponibles`
              : 'Temporalmente sin stock'}
          </StockInfo>
        </ProductActions>
      </ProductInfo>
    </ProductDetailContainer>
  );
}

export default ProductDetail; 