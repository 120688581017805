import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedAdminRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Cargando...</div>;
  }

  // Verificar si el usuario está autenticado y es admin
  if (!currentUser || currentUser.email !== 'admin@malala.com') {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedAdminRoute; 