import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from '../../contexts/CartContext';
import { SHIPPING_COST } from '../../config/constants';

const Cart = () => {
  const navigate = useNavigate();
  const { cart, isOpen, toggleCart, removeFromCart, updateQuantity, calculateSubtotal } = useCart();
  
  if (!isOpen) return null;

  const subtotal = calculateSubtotal();
  const total = subtotal + SHIPPING_COST;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      toggleCart();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleOverlayClick}
          />
          <CartContainer
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
          >
            <CloseButton onClick={toggleCart}>&times;</CloseButton>
            <CartHeader>
              <CartTitle>Tu Carrito</CartTitle>
            </CartHeader>

            <CartItems>
              {cart.length === 0 ? (
                <EmptyCartMessage>Tu carrito está vacío</EmptyCartMessage>
              ) : (
                cart.map((item) => (
                  <CartItem key={`${item.id}-${item.size}-${item.color}`}>
                    <CartItemImage 
                      src={item.images?.[0] || '/placeholder-image.jpg'} 
                      alt={item.name} 
                    />
                    <CartItemInfo>
                      <CartItemName>{item.name}</CartItemName>
                      {item.size && <CartItemDetails>Talla: {item.size}</CartItemDetails>}
                      {item.color && <CartItemDetails>Color: {item.color}</CartItemDetails>}
                      <CartItemPrice>
                        {item.discount > 0 ? (
                          <>
                            <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>
                              €{item.price.toFixed(2)}
                            </span>
                            €{(item.price * (1 - item.discount / 100)).toFixed(2)}
                          </>
                        ) : (
                          `€${item.price.toFixed(2)}`
                        )}
                      </CartItemPrice>
                      <QuantityControls>
                        <QuantityButton 
                          onClick={() => updateQuantity(item, Math.max(1, item.quantity - 1))}
                        >
                          -
                        </QuantityButton>
                        <span>{item.quantity}</span>
                        <QuantityButton 
                          onClick={() => updateQuantity(item, item.quantity + 1)}
                        >
                          +
                        </QuantityButton>
                      </QuantityControls>
                      <RemoveButton onClick={() => removeFromCart(item)}>
                        Eliminar
                      </RemoveButton>
                    </CartItemInfo>
                  </CartItem>
                ))
              )}
            </CartItems>

            <CartFooter>
              <CartTotal>
                <div style={{ marginBottom: '1rem' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                    <span>Subtotal</span>
                    <span>€{subtotal.toFixed(2)}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                    <span>Gastos de envío</span>
                    <span>€{SHIPPING_COST.toFixed(2)}</span>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                  <span>Total</span>
                  <span>€{total.toFixed(2)}</span>
                </div>
              </CartTotal>
              <CheckoutButton
                onClick={() => {
                  toggleCart();
                  navigate('/cart');
                }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={cart.length === 0}
              >
                Proceder al Pago
              </CheckoutButton>
            </CartFooter>
          </CartContainer>
        </>
      )}
    </AnimatePresence>
  );
};

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${props => props.theme.zIndex.modal - 1};
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

const CartContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: ${props => props.theme.colors.background.main};
  box-shadow: ${props => props.theme.shadows.lg};
  padding: ${props => props.theme.spacing.xl};
  z-index: ${props => props.theme.zIndex.modal};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 85%;
    padding: ${props => props.theme.spacing.lg};
  }
`;

const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.xl};
  padding-right: 3rem;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 0.5rem;
  }
`;

const CartTitle = styled.h2`
  font-size: ${props => props.theme.typography.fontSize.xl};
  font-weight: ${props => props.theme.typography.fontWeight.bold};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => props.theme.typography.fontSize.lg};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: ${props => props.theme.typography.fontSize.xl};
  color: ${props => props.theme.colors.text.primary};
  cursor: pointer;
  padding: ${props => props.theme.spacing.sm};
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: ${props => props.theme.zIndex.modal + 1};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: ${props => props.theme.spacing.md};
  }
`;

const CartItems = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: ${props => props.theme.spacing.xl} 0;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin: ${props => props.theme.spacing.lg} 0;
  }
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.md};
  padding: ${props => props.theme.spacing.md} 0;
  border-bottom: 1px solid ${props => props.theme.colors.border.light};

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    gap: ${props => props.theme.spacing.sm};
  }
`;

const CartItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: ${props => props.theme.borderRadius.sm};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 60px;
    height: 60px;
  }
`;

const CartItemInfo = styled.div`
  flex: 1;
`;

const CartItemName = styled.h3`
  font-size: ${props => props.theme.typography.fontSize.md};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  margin: 0;
`;

const CartItemPrice = styled.div`
  font-size: ${props => props.theme.typography.fontSize.md};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  margin: 0.5rem 0;
`;

const CartFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.border.light};
  padding-top: ${props => props.theme.spacing.lg};
  margin-top: auto;
`;

const CartTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${props => props.theme.typography.fontSize.lg};
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  margin-bottom: ${props => props.theme.spacing.lg};
`;

const CheckoutButton = styled(motion.button)`
  width: 100%;
  padding: ${props => props.theme.spacing.md};
  background-color: ${props => props.theme.colors.primary.main};
  color: white;
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  font-size: ${props => props.theme.typography.fontSize.md};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background-color: #e0e0e0;
    color: #666;
    cursor: not-allowed;
    opacity: 1;
  }
`;

const CartButton = styled.button`
  background: #000;
  color: white;
  border: none;
  padding: 1rem 2rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 1rem;

  &:hover {
    background: #333;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #e0e0e0;
    color: #666;
    cursor: not-allowed;
    transform: none;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  text-decoration: underline;

  &:hover {
    color: #000;
  }
`;

const TotalPrice = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin: 1.5rem 0;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const QuantityButton = styled.button`
  background: none;
  border: 1px solid #000;
  color: #000;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #000;
    color: white;
  }

  &:disabled {
    border-color: #e0e0e0;
    color: #e0e0e0;
    cursor: not-allowed;
  }
`;

const EmptyCartMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${props => props.theme.colors.text.secondary};
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
`;

const CartItemDetails = styled.p`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.text.secondary};
  margin: 0.25rem 0;
`;

export default Cart;