import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';

const StyledMalalaLogo = styled.svg`
  width: 50px;
  height: 50px;
`;

const MalalaLogo = ({ isScrolled, isShop, isProduct, isAuthPage, isProfilePage }) => {
  const theme = useTheme();
  return (
    <StyledMalalaLogo viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle 
        cx="512" 
        cy="512" 
        r="500" 
        stroke={isShop || isProduct || isScrolled || isAuthPage || isProfilePage ? "#000" : "#fff"}
        strokeWidth="16"
      />
      <text 
        x="512" 
        y="512" 
        textAnchor="middle" 
        dominantBaseline="middle" 
        fill={isShop || isProduct || isScrolled || isAuthPage || isProfilePage ? "#000" : "#fff"}
        style={{ fontSize: '300px', fontFamily: 'Trajan Pro, serif', fontWeight: '300' }}
      >
        ML
      </text>
    </StyledMalalaLogo>
  );
};

const CartIcon = ({ isScrolled, isShop, isProduct, isProfilePage, isAuthPage }) => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke={isShop || isProduct || isScrolled || isProfilePage || isAuthPage ? "#000" : "#fff"}
    strokeWidth="1.5"
  >
    <path d="M4 4h2l2 11h10l2-9h-12" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="9" cy="20" r="1"/>
    <circle cx="17" cy="20" r="1"/>
  </svg>
);

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${props => props.theme.zIndex.header};
  padding: ${props => props.theme.spacing.sm} 0;
  transition: all ${props => props.theme.transitions.normal};
  background: ${props => 
    props.isScrolled || props.isShop || props.isProduct || props.isAuthPage || props.isProfilePage 
      ? 'rgba(255, 255, 255, 0.95)' 
      : 'transparent'
  };
  backdrop-filter: ${props => 
    props.isScrolled || props.isShop || props.isProduct || props.isAuthPage || props.isProfilePage 
      ? 'blur(10px)' 
      : 'none'
  };
  box-shadow: ${props => 
    props.isScrolled || props.isShop || props.isProduct || props.isAuthPage || props.isProfilePage 
      ? '0 2px 10px rgba(0,0,0,0.1)' 
      : 'none'
  };

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    background: transparent;
    backdrop-filter: none;
    box-shadow: none;
  }
`;

const NavContainer = styled.div`
  max-width: ${props => props.theme.breakpoints.xl};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative;
  width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.md};
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 2rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.md};
  text-decoration: none;
  cursor: pointer;
`;

const LogoWrapper = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.theme.spacing.sm};

  svg {
    width: 100%;
    height: 100%;
    transition: all ${props => props.theme.transitions.normal};
    stroke: ${props => {
      if (props.isShop || props.isProduct || props.isScrolled || props.isAuthPage || props.isProfilePage) return '#000';
      return '#fff';
    }};
  }
`;

const Logo = styled.span`
  font-family: ${props => props.theme.typography.fontFamily.primary};
  font-size: ${props => props.theme.typography.fontSize.xl};
  color: ${props => {
    if (props.isShop || props.isProduct || props.isScrolled || props.isAuthPage || props.isProfilePage) return '#000';
    return '#fff';
  }};
  text-decoration: none;
  font-weight: ${props => props.theme.typography.fontWeight.bold};
  letter-spacing: 2px;
  transition: all ${props => props.theme.transitions.normal};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => props.theme.typography.fontSize.lg};
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const AuthLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CartIconContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  font-size: 0.75rem;
  color: ${props => {
    if (props.isShop || props.isProduct || props.isScrolled || props.isAuthPage || props.isProfilePage) return '#000';
    return '#fff';
  }};
  text-decoration: none;
  transition: all 0.3s ease;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #000;
    transition: width 0.3s ease;
  }

  &:hover {
    opacity: 1;
    &:after {
      width: 100%;
    }
  }
`;

const CartButton = styled.button`
  position: relative;
  padding: ${props => props.theme.spacing.sm};
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => {
    if (props.isShop || props.isProduct || props.isScrolled || props.isAuthPage || props.isProfilePage) return '#000';
    return '#fff';
  }};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const CartCount = styled(motion.span)`
  position: absolute;
  top: -8px;
  right: -8px;
  background: black;
  color: white;
  font-size: 0.7rem;
  padding: 2px 6px;
  border-radius: 10px;
  min-width: 20px;
  text-align: center;
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: none;
  backdrop-filter: none;
  padding: ${props => props.theme.spacing.lg};
  box-shadow: none;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.lg};
  }
`;

const UserMenu = styled(motion.div)`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  padding: 1rem;
  border-radius: 4px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
`;

const UserButton = styled.button`
  background: none;
  border: none;
  padding: ${props => props.theme.spacing.sm};
  cursor: pointer;
  color: ${props => props.isScrolled ? '#000' : '#fff'};
  transition: all ${props => props.theme.transitions.fast};
  letter-spacing: 1px;

  &:hover {
    color: ${props => props.isScrolled ? '#000' : '#fff'};
    opacity: ${props => props.isScrolled ? 0.7 : 0.8};
  }
`;

const UserMenuItem = styled(Link)`
  padding: 0.75rem;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.875rem;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    background: #f5f5f5;
    transform: translateX(5px);
  }
`;

const LogoutButton = styled.button`
  padding: 0.75rem;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 0.875rem;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    background: #f5f5f5;
    transform: translateX(5px);
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: transparent;
  border: 2px solid ${props => props.isScrolled ? '#000' : '#fff'};
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 0;
  color: ${props => props.isScrolled ? '#000' : '#fff'};
  cursor: pointer;
  transition: all ${props => props.theme.transitions.fast};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;

const CartLink = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
`;

const CartDropdown = styled(motion.div)`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 300px;
  background: white;
  padding: 1rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  border-radius: 4px;
  z-index: ${props => props.theme.zIndex.dropdown};
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.border.light};

  &:last-child {
    border-bottom: none;
  }
`;

const CartItemImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
`;

const CartItemInfo = styled.div`
  flex: 1;
`;

const CartItemName = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

const CartItemPrice = styled.p`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.text.secondary};
`;

const CartItemDetails = styled.p`
  font-size: 0.75rem;
  color: ${props => props.theme.colors.text.secondary};
`;

const CartTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid ${props => props.theme.colors.border.light};
  margin-top: 0.5rem;
  font-weight: 500;
`;

const ViewCartButton = styled(Link)`
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: ${props => props.theme.colors.primary.main};
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 1rem;
  transition: background 0.3s ease;

  &:hover {
    background: ${props => props.theme.colors.primary.dark};
  }
`;

const menuVariants = {
  closed: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2
    }
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2
    }
  }
};

const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const { cartItems, toggleCart } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const cartItemsCount = cartItems?.reduce((total, item) => total + (item?.quantity || 0), 0) || 0;
  const isHome = location.pathname === '/';
  const isShop = location.pathname === '/shop';
  const isProduct = location.pathname.includes('/product/');
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';
  const isProfilePage = location.pathname === '/profile';

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
      if (isCartOpen) setIsCartOpen(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isCartOpen]);

  useEffect(() => {
    const handleOpenCart = () => {
      toggleCart();
    };

    window.addEventListener('openCart', handleOpenCart);
    return () => {
      window.removeEventListener('openCart', handleOpenCart);
    };
  }, [toggleCart]);

  const handleLogout = () => {
    logout();
    setShowUserMenu(false);
    navigate('/login');
  };

  const total = cartItems?.reduce((sum, item) => sum + ((item?.price || 0) * (item?.quantity || 0)), 0) || 0;

  return (
    <Nav isScrolled={isScrolled || isShop || isProduct || isAuthPage || isProfilePage}>
      <NavContainer>
        <LogoContainer onClick={() => navigate('/')}>
          <LogoWrapper isScrolled={isScrolled || isProfilePage} isShop={isShop} isProduct={isProduct} isAuthPage={isAuthPage}>
            <MalalaLogo isScrolled={isScrolled || isProfilePage} isShop={isShop} isProduct={isProduct} isAuthPage={isAuthPage} isProfilePage={isProfilePage} />
          </LogoWrapper>
          <Logo isScrolled={isScrolled || isProfilePage} isShop={isShop} isProduct={isProduct} isAuthPage={isAuthPage}>
            MALALA
          </Logo>
        </LogoContainer>

        <NavLinks>
          <NavLink 
            to="/shop" 
            isScrolled={isScrolled || isProfilePage} 
            isShop={isShop} 
            isProduct={isProduct}
            isAuthPage={isAuthPage}
          >
            Tienda
          </NavLink>
          
          {currentUser ? (
            <>
              <NavLink 
                to="/profile" 
                isScrolled={isScrolled || isProfilePage} 
                isShop={isShop} 
                isProduct={isProduct}
                isAuthPage={isAuthPage}
              >
                Cuenta
              </NavLink>
              {showUserMenu && (
                <UserMenu
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  <UserMenuItem to="/profile">Mi Perfil</UserMenuItem>
                  <UserMenuItem to="/orders">Mis Pedidos</UserMenuItem>
                  <LogoutButton onClick={handleLogout}>Cerrar Sesión</LogoutButton>
                </UserMenu>
              )}
            </>
          ) : (
            <NavLink 
              to="/login" 
              isScrolled={isScrolled || isProfilePage} 
              isShop={isShop} 
              isProduct={isProduct}
              isAuthPage={isAuthPage}
            >
              Iniciar Sesión
            </NavLink>
          )}

          <CartButton 
            onClick={toggleCart} 
            isScrolled={isScrolled || isProfilePage}
            isAuthPage={isAuthPage}
          >
            <CartIcon 
              isScrolled={isScrolled} 
              isShop={isShop} 
              isProduct={isProduct} 
              isProfilePage={isProfilePage}
              isAuthPage={isAuthPage}
            />
            {cartItemsCount > 0 && (
              <CartCount
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
              >
                {cartItemsCount}
              </CartCount>
            )}
          </CartButton>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navbar; 