export const convertToWebP = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        
        // Convertir a WebP con calidad 0.8 (80%)
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Error al convertir la imagen a WebP'));
            return;
          }
          
          // Crear un nuevo archivo con el mismo nombre pero extensión .webp
          const fileName = file.name.replace(/\.[^/.]+$/, '.webp');
          const webpFile = new File([blob], fileName, {
            type: 'image/webp',
            lastModified: new Date().getTime()
          });
          
          resolve(webpFile);
        }, 'image/webp', 0.8);
      };
      
      img.onerror = () => {
        reject(new Error('Error al cargar la imagen'));
      };
      
      img.src = e.target.result;
    };
    
    reader.onerror = () => {
      reject(new Error('Error al leer el archivo'));
    };
    
    reader.readAsDataURL(file);
  });
};

export const optimizeImage = async (file, maxWidth = 1920) => {
  // Si el archivo no es válido, devolver error
  if (!file || !(file instanceof File)) {
    console.error('El archivo proporcionado no es válido:', file);
    throw new Error('El archivo proporcionado no es válido');
  }

  console.log('Optimizando imagen:', file.name, 'Tamaño:', Math.round(file.size / 1024), 'KB');
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log('Archivo leído correctamente');
      
      const img = new Image();
      img.onload = () => {
        console.log('Imagen cargada, dimensiones originales:', img.width, 'x', img.height);
        
        try {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          
          // Redimensionar si la imagen es más ancha que maxWidth
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
            console.log('Redimensionando a:', width, 'x', height);
          }
          
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Intentar primero con WebP
          canvas.toBlob((blob) => {
            if (!blob) {
              console.error('Error al generar WebP, intentando con formato original');
              // Si falla WebP, intentar con el formato original
              canvas.toBlob((jpgBlob) => {
                if (!jpgBlob) {
                  reject(new Error('Error al optimizar la imagen en cualquier formato'));
                  return;
                }
                
                const fileName = file.name;
                const optimizedFile = new File([jpgBlob], fileName, {
                  type: file.type,
                  lastModified: new Date().getTime()
                });
                
                console.log('Imagen optimizada en formato original:', optimizedFile.name, 'Tamaño:', Math.round(optimizedFile.size / 1024), 'KB');
                resolve(optimizedFile);
              }, file.type, 0.8);
              return;
            }
            
            const fileName = file.name.replace(/\.[^/.]+$/, '.webp');
            const optimizedFile = new File([blob], fileName, {
              type: 'image/webp',
              lastModified: new Date().getTime()
            });
            
            console.log('Imagen optimizada como WebP:', optimizedFile.name, 'Tamaño:', Math.round(optimizedFile.size / 1024), 'KB');
            resolve(optimizedFile);
          }, 'image/webp', 0.8);
        } catch (error) {
          console.error('Error durante la optimización:', error);
          reject(error);
        }
      };
      
      img.onerror = (err) => {
        console.error('Error al cargar la imagen en el objeto Image:', err);
        reject(new Error('Error al cargar la imagen'));
      };
      
      img.src = e.target.result;
    };
    
    reader.onerror = (err) => {
      console.error('Error al leer el archivo con FileReader:', err);
      reject(new Error('Error al leer el archivo'));
    };
    
    reader.readAsDataURL(file);
  });
}; 