import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import OptimizedImage from '../../components/OptimizedImage';

const HomeContainer = styled.div`
  min-height: 100vh;
`;

const HeroSection = styled.section`
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    margin-top: -60px; // Para compensar el header móvil
  }
`;

const HeroImage = styled(OptimizedImage)`
  width: 100%;
  height: 100%;
  filter: brightness(0.9);
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 90%;
  max-width: 800px;
  z-index: 2;
  padding: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem;
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
  letter-spacing: 4px;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
`;

const HeroSubtitle = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
`;

const ShopNowButton = styled(Link)`
  display: inline-block;
  padding: 1.2rem 3rem;
  background-color: ${props => props.theme.colors.secondary.main};
  color: ${props => props.theme.colors.primary.main};
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all ${props => props.theme.transitions.normal};

  @media (max-width: 768px) {
    padding: 1rem 2.5rem;
    font-size: 1rem;
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary.main};
    color: ${props => props.theme.colors.secondary.main};
    transform: translateY(-2px);
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: ${props => props.theme.typography.fontSize.xxl};
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing.xl};
  font-weight: ${props => props.theme.typography.fontWeight.light};
  letter-spacing: 2px;
`;

const CategoriesSection = styled.section`
  padding: 6rem 4rem;
  background: white;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 4rem 2rem;
  }
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    gap: 2rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const CategoryImage = styled(OptimizedImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
`;

const CategoryTitle = styled.h3`
  font-size: 2rem;
  font-weight: 300;
  margin: 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  z-index: 2;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const CategoryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
`;

const CategoryCard = styled(Link)`
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  text-decoration: none;
  display: block;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    height: 400px;
    aspect-ratio: auto;
    border-radius: 8px;
  }

  &:hover {
    ${CategoryImage} {
      transform: scale(1.05);
    }
    
    ${CategoryOverlay} {
      background: rgba(0, 0, 0, 0.4);
    }

    ${CategoryTitle} {
      transform: translateY(-10px);
    }
  }
`;

const CategoryDescription = styled.p`
  font-size: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 1rem;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const CategoryButton = styled.span`
  display: inline-block;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid white;
`;

const FeaturedProductsSection = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const MainProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  margin: 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const MainProduct = styled(motion.div)`
  position: relative;
  height: 90vh;
  overflow: hidden;
  cursor: pointer;
  will-change: transform;
  transform: translateZ(0);

  @media (max-width: 768px) {
    height: 300px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s ease;
    will-change: transform;

    @media (max-width: 768px) {
      object-fit: cover;
      object-position: center;
      transform: none;
      scale: 1;
    }
  }

  &:hover img {
    transform: scale(1.05);
    @media (max-width: 768px) {
      transform: scale(1.02);
    }
  }
`;

const SmallProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0;
  margin-top: 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SmallProduct = styled(motion.div)`
  position: relative;
  height: 45vh;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 300px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const ProductPrice = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 0.9rem;
  color: white;
`;

const NewCollectionSection = styled.section`
  padding: ${props => props.theme.spacing.xxl} 0;
  background: ${props => props.theme.colors.background.light};
`;

const CollectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: ${props => props.theme.breakpoints.xl};
  margin: 0 auto;
  min-height: 600px;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const CollectionContent = styled.div`
  padding: ${props => props.theme.spacing.xxl} ${props => props.theme.spacing.xl};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  h2 {
    font-size: 3rem;
    margin-bottom: ${props => props.theme.spacing.xl};
    font-weight: 300;
    letter-spacing: 2px;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: ${props => props.theme.spacing.xl};
    line-height: 1.6;
    max-width: 500px;
    color: ${props => props.theme.colors.text.secondary};
  }
`;

const CollectionImage = styled.div`
  height: 100%;
  width: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 400px;
  }
`;

const NewsletterSection = styled.section`
  padding: ${props => props.theme.spacing.xxl} 0;
  text-align: right;
  background: #f8f9fa;
`;

const NewsletterForm = styled.form`
  max-width: 500px;
  margin: 0 auto;
  margin-right: 2rem;
  display: flex;
  gap: ${props => props.theme.spacing.md};
  padding: 0 ${props => props.theme.spacing.xl};
  
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto;
    padding: 0 1rem;
  }
`;

const NewsletterInput = styled.input`
  flex: 1;
  padding: ${props => props.theme.spacing.md} 0;
  border: none;
  border-bottom: 1px solid #ddd;
  background: transparent;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-bottom-color: ${props => props.theme.colors.primary.main};
  }
`;

const NewsletterButton = styled(motion.button)`
  padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.xl};
  background: ${props => props.theme.colors.primary.main};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
  
  &:hover {
    background: ${props => props.theme.colors.primary.dark};
  }
`;

const LazyNewCollectionSection = lazy(() => import('./sections/NewCollectionSection'));
const LazyNewsletterSection = lazy(() => import('./sections/NewsletterSection'));

const LoadingFallback = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
`;

const Home = () => {
  const [email, setEmail] = useState('');
  const [featuredProducts, setFeaturedProducts] = useState({
    mainProducts: [],
    smallProducts: []
  });
  
  const defaultCategories = [
    {
      id: 'ropa',
      title: 'Ropa',
      image: 'https://images.unsplash.com/photo-1515372039744-b8f02a3ae446?auto=format&fit=crop&w=800'
    },
    {
      id: 'accesorios',
      title: 'Accesorios',
      image: 'https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?auto=format&fit=crop&w=800'
    },
    {
      id: 'calzado',
      title: 'Calzado',
      image: 'https://images.unsplash.com/photo-1594633312681-425c7b97ccd1?auto=format&fit=crop&w=800'
    }
  ];

  const [contentSettings, setContentSettings] = useState({
    hero: {
      image: '',
      title: '',
      subtitle: ''
    },
    categories: defaultCategories
  });

  useEffect(() => {
    const fetchContentSettings = async () => {
      try {
        const docRef = doc(db, 'settings', 'content');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const updatedCategories = data.categories?.length > 0 
            ? data.categories.map(cat => ({
                id: cat.id || '',
                title: cat.title || '',
                image: cat.image || ''
              })).filter(cat => cat.id)
            : defaultCategories;

          setContentSettings(prev => ({
            ...prev,
            hero: data.hero || prev.hero,
            categories: updatedCategories
          }));
        }
      } catch (error) {
        console.error('Error al cargar el contenido:', error);
        setContentSettings(prev => ({
          ...prev,
          categories: defaultCategories
        }));
      }
    };

    const fetchFeaturedProducts = async () => {
      try {
        const docRef = doc(db, 'settings', 'featuredProducts');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setFeaturedProducts(docSnap.data());
        }
      } catch (error) {
        console.error('Error al cargar productos destacados:', error);
      }
    };

    fetchContentSettings();
    fetchFeaturedProducts();
  }, []);

  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    console.log('Email suscrito:', email);
    setEmail('');
  };

  return (
    <HomeContainer>
      <HeroSection>
        {contentSettings.hero.image && (
          <>
            <HeroImage
              src={contentSettings.hero.image}
              alt="Hero"
              initial={{ scale: 1.1 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.8, useNativeDriver: true }}
              style={{ filter: 'brightness(0.7)' }}
              priority={true}
              loading="eager"
            />
            <HeroContent
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, useNativeDriver: true }}
            >
              <HeroTitle>{contentSettings.hero.title}</HeroTitle>
              <HeroSubtitle>{contentSettings.hero.subtitle}</HeroSubtitle>
              <ShopNowButton to="/shop">Descubrir Colección</ShopNowButton>
            </HeroContent>
          </>
        )}
      </HeroSection>

      <CategoriesSection>
        <CategoriesGrid>
          {defaultCategories.map((category) => (
            <CategoryCard 
              key={category.id}
              to={`/shop?category=${category.id}`}
            >
              <CategoryImage
                src={category.image}
                alt={category.title}
                loading={category.id === 'vestidos' ? "eager" : "lazy"}
              />
              <CategoryOverlay>
                <CategoryTitle>{category.title}</CategoryTitle>
              </CategoryOverlay>
            </CategoryCard>
          ))}
        </CategoriesGrid>
      </CategoriesSection>

      <FeaturedProductsSection>
        <MainProductsGrid>
          {featuredProducts.mainProducts.map((product, index) => (
            <MainProduct
              key={`main-${index}`}
              as={Link}
              to={`/product/${product.id}`}
            >
              <OptimizedImage 
                src={product.images?.[0]} 
                alt={product.name} 
                height="100%"
                style={{
                  objectPosition: 'center 30%'
                }}
              />
              <ProductPrice>{product.price}€</ProductPrice>
            </MainProduct>
          ))}
        </MainProductsGrid>

        <SmallProductsGrid>
          {featuredProducts.smallProducts.map((product, index) => (
            <SmallProduct
              key={`small-${index}`}
              as={Link}
              to={`/product/${product.id}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 * index }}
            >
              <OptimizedImage 
                src={product.images?.[0]} 
                alt={product.name} 
                height="100%"
              />
              <ProductPrice>{product.price}€</ProductPrice>
            </SmallProduct>
          ))}
        </SmallProductsGrid>
      </FeaturedProductsSection>

      <Suspense fallback={<LoadingFallback>Cargando...</LoadingFallback>}>
        <LazyNewCollectionSection />
      </Suspense>

      <Suspense fallback={<LoadingFallback>Cargando...</LoadingFallback>}>
        <LazyNewsletterSection />
      </Suspense>
    </HomeContainer>
  );
};

export default Home; 